import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { TooltipPosition, ITooltipConfig } from '@ra-web-tech-ui-toolkit/components';

@Component({
  selector: 'app-ag-credits-activity',
  templateUrl: './ag-credits-activity.component.html',
  styleUrls: [],
})
export class AgTokensActivityComponent implements ICellRendererAngularComp {
  readonly tooltipConfig: ITooltipConfig = { position: TooltipPosition.Right, tooltipClass: 'tooltip-ledger' };
  tokenActivity: string;
  discountDetailsTooltip: string;

  refresh(): boolean {
    return false;
  }

  agInit(params: any): void {
    this.tokenActivity = params.mapTokenConsumption(params);
    this.discountDetailsTooltip = params.generateDiscountTooltip(params);
  }
}
