import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { CHIP_COLORS, ChipItems, IChipItem } from '@ra-web-tech-ui-toolkit/components';

@Component({
  selector: 'app-email-input',
  templateUrl: './email-input.component.html',
  styleUrls: ['./email-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EmailInputComponent implements OnChanges {
  newChipValue = '';
  isNewChipSelected: boolean = false;
  selectedChipColor = CHIP_COLORS.Default;
  isChipSelectable = true;
  chipItems: ChipItems = [];
  invalidEmailIdFound: boolean = false;
  @Output() emailIds = new EventEmitter();
  @Output() clearEmailList = new EventEmitter();
  @Output() hasError = new EventEmitter();
  @Input() resetEmailList = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.resetEmailList.currentValue ||
      changes.resetEmailList.currentValue === changes.resetEmailList.previousValue
    ) {
      this.chipItems = [];
      this.emailIds.emit([]);
    }
  }

  addEmail(event): void {
    const emailList: string[] = event.value.split(/[,; ]+/).filter((err) => err.length > 0);

    for (const email of emailList) {
      if (email) {
        if (this.validateEmail(email)) {
          const newChip: IChipItem = {
            config: {
              color: CHIP_COLORS.Default,
              selectable: false,
            },
            isSelected: false,
            value: email,
          };
          this.chipItems = [...this.chipItems, newChip];
        } else {
          const newChip: IChipItem = {
            config: {
              color: CHIP_COLORS.Error,
              selectable: false,
            },
            isSelected: true,
            value: email,
          };
          this.chipItems = [...this.chipItems, newChip];
        }
      }
      this.newChipValue = '';
      this.getErrorStatus();
      this.setEmailIds(this.chipItems);
    }
  }

  validateEmail(email: string): boolean {
    const regularExpression =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValidEmail = regularExpression.test(String(email).toLowerCase());
    if (!isValidEmail) {
      this.invalidEmailIdFound = true;
      this.getErrorStatus();
    }
    return isValidEmail;
  }

  removeEmail($event: any): void {
    this.chipItems = this.chipItems.filter((email) => {
      return email.value !== $event.value;
    });
    this.getErrorStatus();
    this.setEmailIds(this.chipItems);
  }

  setEmailIds(emailIds: any): void {
    const filteredEmailIds = emailIds.map(function (email) {
      return email.value;
    });
    this.emailIds.emit(filteredEmailIds);
    this.clearEmailList.emit(false);
  }

  getErrorStatus(): void {
    this.invalidEmailIdFound = this.chipItems.some((email) => email.isSelected === true);
    this.hasError.emit(this.invalidEmailIdFound);
  }
}
