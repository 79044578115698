import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { getInvitationStatus, getTimeLeft } from '@app/v2/shared/utils';
import { InvitationsStatus } from '@ra-state';
import { ITooltipConfig, TooltipPosition } from '@ra-web-tech-ui-toolkit/components';

@Component({
  selector: 'app-ag-invitation-status-renderer',
  templateUrl: './ag-invitation-status-renderer.component.html',
  styleUrls: ['./ag-invitation-status-renderer.component.scss'],
})
export class AgInvitationStatusRendererComponent implements ICellRendererAngularComp {
  params: any;
  readonly tooltipConfig: ITooltipConfig = { position: TooltipPosition.Top, maxWidth: 400 };

  getInvitationStatus = getInvitationStatus;

  refresh(): boolean {
    return false;
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
  get Status(): InvitationsStatus {
    return this.params?.data?.status;
  }
  get IsAcceptedByOther(): boolean {
    return this.params?.data?.acceptedByEmail !== this.params?.data?.toEmail;
  }

  goToEditUserAccess(userId: string): void {
    this.router.navigate(['/access-mngmt/edit'], { queryParams: { userId: userId } });
  }

  getExpiryDuration(expiry: string): string {
    return `Expires in ${getTimeLeft(expiry)}`;
  }
  constructor(public router: Router) {}
}
