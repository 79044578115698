import { createSelector } from '@ngrx/store';
import { AppState, Trials } from '../lemans-app.model';

const stateSelector = (state: AppState): Trials => state.trials;

const selectTrialsState = createSelector(stateSelector, (trialState: Trials) => trialState);

export const selectTrialReservation = createSelector(selectTrialsState, (state: Trials) => state.reservation);

export const selectTrialBundles = createSelector(selectTrialsState, (state: Trials) => state.bundles);
export const selectRefreshTrialsGrid = createSelector(selectTrialsState, (state: Trials) => state.refreshGrid);
