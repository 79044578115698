import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { maxDate, minDate } from '@core/common-constants';

@Pipe({ name: 'customDate' })
export class CustomDatePipe implements PipeTransform {
  transform(value: any, format: string = 'mediumDate', locale: string = 'en-US'): any {
    const datePipe = new DatePipe(locale);
    if (!value) {
      return '';
    }
    const inputDate = new Date(value);
    const minimumDate = new Date(minDate);
    const maximumDate = new Date(maxDate);
    if (inputDate <= minimumDate) {
      return '-';
    } else if (inputDate >= maximumDate) {
      return 'NA';
    }
    return datePipe.transform(value, format);
  }
}
