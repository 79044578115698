import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Connection } from '@app/models/config.model';
import { environment } from '@environments/environment';
import { ControlPageFacade, FeatureFlagsFacade, LoginError, LoginErrorCode } from '@ra-state';
import { filter, map, tap, withLatestFrom } from 'rxjs';
import { AuthenticationService } from '../auth.service';

@Component({
  selector: 'app-landing',
  styleUrls: ['./landing.component.scss'],
  templateUrl: './landing.component.html',
})
export class LandingComponent {
  returnTo: string;
  allowTestUsers: boolean = false;
  landingImageURL: string = `${environment.appConfiguration.csPortalUrl}/assets/images/LeMans-Cloud-Services.jpg`;
  isAllowTestUsers$ = this.featureFlagsFacade.getFlagValue$('allow_test_users').pipe(
    filter((allowTestUsers) => allowTestUsers !== undefined),
    tap((allowTestUsers) => {
      this.allowTestUsers = Boolean(allowTestUsers);
    }),
  );

  isAuthenticated$ = this.authenticationService.isAuthenticated$.pipe(
    withLatestFrom(this.route.queryParams),
    tap(([isAuthenticated, params]) => {
      this.returnTo = this.getReturnTo(params);
      if (isAuthenticated) {
        this.router.navigateByUrl(this.returnTo);
      }
    }),
    map(([isAuthenticated, _params]) => isAuthenticated),
  );

  loginError$ = this.authenticationService.error$.pipe(
    tap((errorContext: any) => {
      try {
        const loginError: LoginError = JSON.parse(errorContext.error_description);
        if (loginError) {
          this.controlPageFacade.setUserLoginFailed(loginError);
        }
      } catch (_exp) {
        this.controlPageFacade.setUserLoginFailed({
          errorCode: LoginErrorCode.Unknown,
          errorDescription: LoginErrorCode.Unknown,
          email: LoginErrorCode.Unknown,
        });
      }
    }),
  );

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly route: ActivatedRoute,
    private readonly featureFlagsFacade: FeatureFlagsFacade,
    private readonly router: Router,
    private readonly controlPageFacade: ControlPageFacade,
  ) {}

  getReturnTo(params: Params): string {
    const searchParams = new URLSearchParams();
    for (const key of Object.keys(params)) {
      if (key !== 'returnTo') {
        searchParams.set(key, params[key]);
      }
    }
    const searchParamsStr = searchParams.toString();
    let returnTo = `${params.returnTo}`;
    if (searchParamsStr) {
      returnTo = returnTo.concat(`?${searchParams.toString()}`);
    }
    return returnTo;
  }

  login(): void {
    if (this.allowTestUsers) {
      this.authenticationService.loginWithTestUsers(this.returnTo);
    } else {
      this.authenticationService.login(Connection.Rockwell, this.returnTo);
    }
  }
}
