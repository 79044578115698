import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LayoutModule } from '@rockwell-automation-inc/layout';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { LandingComponent } from './landing/landing.component';

@NgModule({
  declarations: [AuthCallbackComponent, LandingComponent],
  imports: [CommonModule, LayoutModule],
})
export class AuthenticationModule {}
