import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  ColDef,
  FilterModel,
  FirstDataRenderedEvent,
  GetRowIdFunc,
  GridOptions,
  GridReadyEvent,
  IServerSideDatasource,
  Module,
  ProcessCellForExportParams,
  RowDataUpdatedEvent,
} from '@ag-grid-community/core';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AgEditRemoveAccessButtonComponent } from '@app/v2/access-management/ag-remove-access-button/ag-remove-access-button.component';
import { AgRemoveButtonComponent } from '@app/v2/access-management/ag-remove-button/ag-remove-button.component';
import { AgSwitchButtonComponent } from '@app/v2/access-management/ag-switch-button/ag-switch-button.component';
import { AgAllocateBtnComponent } from '@app/v2/entitlement/ag-allocate-btn/ag-allocate-btn.component';
import { AgBundleDetailsComponent } from '@app/v2/entitlement/ag-bundle-details/ag-bundle-details.component';
import { AgDeallocateBtnComponent } from '@app/v2/entitlement/ag-deallocate-btn/ag-deallocate-btn.component';
import { AgServiceDetailsComponent } from '@app/v2/entitlement/ag-service-details/ag-service-details.component';
import { AgStatusRendererComponent } from '@app/v2/entitlement/ag-status-renderer/ag-status-renderer.component';
import { AgInvitationActionButtonComponent } from '@app/v2/invitation-management/ag-invitation-action-button/ag-invitation-action-button.component';
import { AgInvitationStatusRendererComponent } from '@app/v2/invitation-management/ag-invitation-status-renderer/ag-invitation-status-renderer.component';
import { OrganizationColumnRendererComponent } from '@app/v2/organizations/join/organization-column-renderer.component';
import { AgTokensActivityComponent } from '@app/v2/utility-tokens/ledger/ag-credits-activity/ag-credits-activity.component';
import { GridRowModelTypes } from '@ra-web-tech-ui-toolkit/components';
import { filter, Observable, Subscription, tap } from 'rxjs';
import { AgCustomNoRowsOverlayComponent } from '../ag-renderers/ag-custom-no-rows-overlay/ag-custom-no-rows-overlay.component';
import { AgTimeFormatComponent } from '../ag-renderers/ag-time-format.component';
import { AgTwoButtonRenderComponent } from '../ag-renderers/ag-two-buttons-render/ag-two-buttons-render.component';
import { CustomDatePipe } from '../pipes/date/custom-date.pipe';
import { formatEntitlementCopyColumnContent } from '../utils';

@Component({
  selector: 'app-ag-toolbar-grid',
  templateUrl: './ag-toolbar-grid.component.html',
  styleUrl: './ag-toolbar-grid.component.scss',
})
export class AgToolbarGridComponent implements OnInit, OnDestroy {
  @Input() noRowsOverlayMsg: string = 'No matching rows found';
  @Input() noRowsOverlayContent: string = '';
  @Input() columnDefs!: ColDef[];
  @Input() pageSize: number = 10;
  @Input() gridOptions!: GridOptions;
  @Input() dataProvider?: () => IServerSideDatasource;
  @Input() isSortFilterApplied?: boolean;
  @Input() rowData$?: Observable<any>;
  @Input() showToolbar: boolean = true;
  @Output() gridReady = new EventEmitter<any>();
  @Output() firstDataRendered = new EventEmitter<any>();
  @Output() rowDataUpdated = new EventEmitter<any>();
  @Output() clearAllSortFilters = new EventEmitter<void>();
  @Input() getRowId?: GetRowIdFunc<any>;
  @Input() defaultFilter?: FilterModel;
  @Input() refreshOn$?: Observable<boolean | undefined>;

  DEFAULT_GRID_OPTIONS: GridOptions = {
    animateRows: true,
    domLayout: 'autoHeight',
    enableRangeSelection: true,
    pagination: true,
    rowSelection: 'single',
    suppressMenuHide: true,
    suppressRowClickSelection: true,
  };

  gridApi: any;
  private subscription: Subscription;

  ngOnInit(): void {
    const defaultColDef = {
      minWidth: 100,
      autoHeight: true,
      filter: false,
      flex: 1,
      resizable: true,
      unSortIcon: true,
    };
    const getRowHeight = (): number => 60;
    const loadingTemplate = '<span class="ag-overlay-loading-center">Data is loading...</span>';
    const noRowsOverlayComponentParams: any = {
      title: this.noRowsOverlayMsg,
      content: this.noRowsOverlayContent,
    };
    // fix names for consistency
    const components = {
      serviceDetailsRenderer: AgServiceDetailsComponent,
      statusRenderer: AgStatusRendererComponent,
      timeFormatRenderer: AgTimeFormatComponent,
      twoButtonRender: AgTwoButtonRenderComponent,
      editRemoveAccessButtonRenderer: AgEditRemoveAccessButtonComponent,
      bundleDetailsRenderer: AgBundleDetailsComponent,
      allocateButtonRenderer: AgAllocateBtnComponent,
      switchButtonRenderer: AgSwitchButtonComponent,
      deallocateButtonRenderer: AgDeallocateBtnComponent,
      removeButtonRenderer: AgRemoveButtonComponent,
      organizationColumnRenderer: OrganizationColumnRendererComponent,
      agInvitationActionButtonComponent: AgInvitationActionButtonComponent,
      agInvitationStatusRendererComponent: AgInvitationStatusRendererComponent,
      creditsActivity: AgTokensActivityComponent,
    };
    const otherInitialOptions: GridOptions = {
      columnDefs: this.columnDefs,
      components: components,
      defaultColDef: defaultColDef,
      getRowHeight: getRowHeight,
      noRowsOverlayComponent: AgCustomNoRowsOverlayComponent,
      noRowsOverlayComponentParams: noRowsOverlayComponentParams,
      overlayLoadingTemplate: loadingTemplate,
      paginationPageSize: this.pageSize,
      paginationPageSizeSelector: false,
      processCellForClipboard: this.processCellForClipboard.bind(this),
    };

    this.gridOptions = Object.assign({}, this.DEFAULT_GRID_OPTIONS, otherInitialOptions, this.gridOptions);

    if (this.dataProvider) {
      this.gridOptions.rowModelType = 'serverSide';
      this.gridOptions.serverSideDatasource = this.dataProvider();
      this.gridOptions.cacheBlockSize = this.pageSize;
    } else {
      this.gridOptions.rowModelType = GridRowModelTypes.ClientSide;
    }

    this.subscription =
      this.refreshOn$
        ?.pipe(
          filter((refresh) => refresh !== undefined && refresh),
          tap(() => {
            this.gridApi?.refreshServerSide({});
          }),
        )
        .subscribe() || new Subscription();
  }

  get isSortFilterAppliedImpl(): boolean {
    return (
      this.isSortFilterApplied ??
      (this.gridApi?.isAnyFilterPresent() ||
        this.gridApi?.getColumnState().some((cs) => {
          const defaulColDef = this.columnDefs.find((cd) => cd.field === cs.colId);
          return cs.sort && cs.sort !== defaulColDef?.initialSort;
        }) ||
        false)
    );
  }

  modules: Module[] = [
    ServerSideRowModelModule,
    RangeSelectionModule,
    SetFilterModule,
    ClipboardModule,
    ClientSideRowModelModule,
    ExcelExportModule,
  ];

  onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
    this.gridApi?.showLoadingOverlay();
    this.gridApi?.setFilterModel(this.defaultFilter);

    this.gridReady.emit(event);
    if (this.rowData$) {
      this.rowData$.subscribe((data) => {
        if (!event.api.isDestroyed()) {
          event.api.updateGridOptions({
            rowData: data,
          });
        }
      });
    }
  }

  onFirstDataRendered(event: FirstDataRenderedEvent): void {
    this.gridApi = event.api;
    this.firstDataRendered.emit(event);
  }

  onRowDataUpdated(event: RowDataUpdatedEvent): void {
    this.gridApi = event.api;
    this.rowDataUpdated.emit(event);
  }

  onClearAllSortFilters(): void {
    this.gridApi?.setFilterModel({});
    this.gridApi.resetColumnState();
    this.gridApi?.paginationGoToFirstPage();
    this.clearAllSortFilters.emit();
  }

  processCellForClipboard(params: ProcessCellForExportParams): any {
    const columnName = params.column['colDef'].field;
    const customDatePipe = new CustomDatePipe();
    if (params?.node) {
      switch (columnName) {
        case 'serviceKind':
          return formatEntitlementCopyColumnContent(params.node.data.entitlementHeaders);
        case 'effectiveDate':
          return customDatePipe.transform(params.node.data.effectiveDate);
        case 'expiryDate':
          return customDatePipe.transform(params.node.data.expiryDate);
        case 'reservedOn':
          return customDatePipe.transform(params.node.data.reservedOn);
        case 'redeemBefore':
          return customDatePipe.transform(params.node.data.redeemBefore);
        case 'redeemedOn':
          return customDatePipe.transform(params.node.data.redeemedOn);
        case 'expiresOn':
          return customDatePipe.transform(params.node.data.expiresOn);
        case 'creditsActivity':
          return params.node.data.tokenConsumptionMapping(params.node);
        default:
          return params.value;
      }
    }
    return params.value;
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  constructor() {}
}
