<span raUiTooltip="{{ Disabled ? AllocateToolTip : null }}" [tooltipConfig]="AllocateTooltipConfig">
  <ra-ui-main-button
    *ngIf="Status === 'Active' || TrialLinkStatus === 'redeemed'"
    label="Allocate"
    (click)="btnClickedHandler()"
    [disabled]="Disabled"
  ></ra-ui-main-button>
</span>
<div class="grey-out" *ngIf="Status === ENTITLEMENT_STATUS.COMPLETED">Expired</div>
<div class="expired-status" *ngIf="TrialLinkStatus === TRIAL_LINK_STATUS.TRIALEXPIRED">
  <i class="ra-icon-ide-md-color-unprovisioned"></i>
  <span>Expired</span>
</div>
<div class="grey-out" *ngIf="Status === ENTITLEMENT_STATUS.CANCELLED">
  Cancelled
  <i
    class="ra-icon-ide-sm-help left-padding"
    [raUiTooltip]="'This entitlement has been cancelled by an administrator.'"
    [tooltipConfig]="tooltipConfig"
  ></i>
</div>
