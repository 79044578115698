import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, OnDestroy } from '@angular/core';
import { CommonConstants } from '@core/common-constants';
import { TrialLinkStatus, TrialStatus, UserDataFacade } from '@ra-state';
import { BADGE_STATUS_COLORS, BadgePosition, BadgeSize, IBadgeConfig } from '@ra-web-tech-ui-toolkit/components';
import { Subscription } from 'rxjs';

@Component({
  styleUrls: ['./ag-time-format.component.scss'],
  templateUrl: './ag-time-format.component.html',
})
export class AgTimeFormatComponent implements ICellRendererAngularComp, OnDestroy {
  params: ICellRendererParams;
  preferredLanguage: string;
  preferredLangsub: Subscription;
  BADGE_STATUS_COLORS = BADGE_STATUS_COLORS;
  BadgePosition = BadgePosition;
  BadgeSize = BadgeSize;
  badgeConfig: IBadgeConfig = {
    position: BadgePosition.Before,
    color: BADGE_STATUS_COLORS.Warning,
    size: BadgeSize.Indicator,
  };
  showExpireSoonFlag: boolean;
  TRIAL_STATUS = TrialStatus;
  LINK_STATUS = TrialLinkStatus;

  isExpiringSoon(): boolean {
    if (!this.showExpireSoonFlag) {
      return false;
    }
    const currentDate = new Date();
    const data = this.params.data;
    const expiresOn = new Date(data.expiresOn);
    const daysToExpire = (expiresOn.getTime() - currentDate.getTime()) / (3600 * 24 * 1000);
    return daysToExpire <= CommonConstants.trialExpireSoonDays && currentDate < expiresOn;
  }
  constructor(private userDataFacade: UserDataFacade) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.showExpireSoonFlag = this.params['showExpireSoonFlag'] || false;
    this.preferredLangsub = this.userDataFacade.preferredLanguage$?.subscribe((value) => {
      this.preferredLanguage = String(value);
    });
  }

  // Optional - Return true if you want to manage a cell refresh yourself, otherwise return false.
  // If you return false, the grid will remove the component from the DOM and create  a new component in its place
  // with the new values.
  refresh(): boolean {
    return false;
  }

  ngOnDestroy(): void {
    this.preferredLangsub?.unsubscribe();
  }
}
