<ng-content *ngIf="userData$ | async"></ng-content>
<ra-ui-outlined-button
  *ngIf="Status === 'Active' && Displayed && showDeallocateBtn"
  label="Deallocate"
  (click)="btnClickedHandler()"
></ra-ui-outlined-button>
<div *ngIf="Status === 'Active' && !Displayed">Active</div>
<div class="grey-out" *ngIf="Status === ENTITLEMENT_STATUS.COMPLETED">Expired</div>
<div class="grey-out" *ngIf="Status === ENTITLEMENT_STATUS.CANCELLED">
  Cancelled
  <i
    class="ra-icon-ide-sm-help left-padding"
    [raUiTooltip]="'This entitlement has been cancelled by an administrator.'"
    [tooltipConfig]="tooltipConfig"
  ></i>
</div>
