import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { concatLatestFrom } from '@ngrx/operators';
import { SnackBarFacade, UserDataFacade } from '@ra-state';
import { Observable, asyncScheduler, iif, mergeMap, of, scheduled, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TenantRoleGuard {
  constructor(
    private userDataFacade: UserDataFacade,
    private router: Router,
    private snackBarFacade: SnackBarFacade,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    const checkTenantPermission$ = this.userDataFacade.effectiveRoles$.pipe(
      concatLatestFrom(() => [this.userDataFacade.currentTenantId$]),
      switchMap(([effectiveRoles, currentTenantId]) => {
        const hasPermission = effectiveRoles?.find(
          (role) => role.resourceId === currentTenantId && route.data.roles?.includes(role.role),
        );
        if (hasPermission) {
          return scheduled<boolean>([true], asyncScheduler);
        } else {
          const message = 'Insufficient Permissions. Redirecting to dashboard';
          this.snackBarFacade.displayMessage({
            message: message,
            type: 'Error',
          });
          this.router.navigate(['/dashboard']);
          return scheduled<boolean>([false], asyncScheduler);
        }
      }),
    );

    return this.userDataFacade.noLastAccessedTenant$.pipe(
      mergeMap((noLastAccessedTenant) =>
        iif(
          () => noLastAccessedTenant === true,
          of(1).pipe(
            switchMap(() => {
              this.router.navigate(['/dashboard']);
              return scheduled<boolean>([false], asyncScheduler);
            }),
          ),
          checkTenantPermission$,
        ),
      ),
    );
  }
}
