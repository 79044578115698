<div class="status-icon" *ngIf="Status === TRIAL_STATUS.ACTIVE.toLowerCase()">
  <i class="ra-icon-ide-md-color-check"></i>
  <span>Active</span>
</div>
<div class="status-icon" *ngIf="Status === TRIAL_STATUS.ENDED.toLowerCase()">
  <i class="ra-icon-ide-md-color-unprovisioned"></i>
  <span>Ended</span>
</div>
<div class="status-icon" *ngIf="Status === TRIAL_STATUS.PROVISIONING.toLowerCase()">
  <i
    class="ra-icon-ide-md-color-alarm-medium-priority"
    [raUiTooltip]="
      'Set up for one or more trial items needs to be completed from within that application. Go to the Hub Home to open.'
    "
    [tooltipConfig]="tooltipConfig"
  ></i>
  <span>Needs action</span>
</div>
