import { Injectable } from '@angular/core';
import { ApiError } from '@core/api-error';
import { Store } from '@ngrx/store';
import {
  AppState,
  LoginError,
  // isPreviewApp,
  selectUserNotFoundState,
} from '@ra-state';
import { NavItem } from '@rockwell-automation-inc/common-utils';
import { UserProfile } from '@rockwell-automation-inc/service';
import { LoggerService } from '@servicesV2/logger.service';
import { filter } from 'rxjs';
import {
  acceptEULA,
  extendSession,
  getEulaConfig,
  loading,
  logoutSession,
  navigateTo,
  navigateToError,
  setEulaStatus,
  setExtendSession,
  setHelpMenuToOpen,
  setInvitationIdSuccess,
  setLogoutSession,
  userLoginComplete,
  userLoginFailed,
} from './control-page.actions';
import {
  getApps,
  getEnabledApps,
  selectApiError,
  selectAppsMap,
  selectCatalogInfo,
  selectEulaConfig,
  selectEulaContent,
  selectEulaState,
  selectExtendSession,
  selectHelpMenuToOpen,
  selectInvitationId,
  selectIsUserActive,
  selectLoadingState,
  selectLoggedInUserProfile,
  selectLogoutSession,
  selectRouteState,
  selectSessionTimedOut,
  selectShowArchiveBanner,
  selectVersions,
} from './control-page.selectors';

@Injectable({
  providedIn: 'root',
})
export class ControlPageFacade {
  isLoading$ = this.store$.select(selectLoadingState);
  getApps$ = this.store$.select(getApps);
  versionInfos$ = this.store$.select(selectVersions);
  getAppsMap$ = this.store$.select(selectAppsMap);
  getEnabledApps$ = this.store$.select(getEnabledApps);
  catalogInfo$ = this.store$.select(selectCatalogInfo);
  sessionTimedOut$ = this.store$.select(selectSessionTimedOut);
  extendSession$ = this.store$.select(selectExtendSession);
  logoutSession$ = this.store$.select(selectLogoutSession);

  // questionable?
  isUserNotFound$ = this.store$.select(selectUserNotFoundState);

  /// EULA should move to user state
  eulaConfig$ = this.store$.select(selectEulaConfig);
  /// why do we even need eula content in state
  eulaContent$ = this.store$.select(selectEulaContent);
  isEulaAccepted$ = this.store$.select(selectEulaState);
  isUserActive$ = this.store$.select(selectIsUserActive);
  loggedInUserProfile$ = this.store$.select(selectLoggedInUserProfile);

  apiError$ = this.store$.select(selectApiError);

  /// questionable? if we use activateroute snapshot, we should get the qp easily
  getInvitationId$ = this.store$.select(selectInvitationId);
  routeState$ = this.store$.select(selectRouteState).pipe(filter((state) => state !== undefined));

  helpMenuToOpen$ = this.store$.select(selectHelpMenuToOpen);

  // move to UI state (peer of snackbar, dialog, etc) and used commonly to display all the diff banners
  // maint window, sessiontimoeout, token exhaustion, archival etc
  showArchiveBanner$ = this.store$.select(selectShowArchiveBanner);

  constructor(
    private readonly logger: LoggerService,
    private readonly store$: Store<AppState>,
  ) {}

  setLoading(value: boolean): void {
    this.store$.dispatch(loading({ value }));
  }

  setEULA(value: boolean): void {
    this.store$.dispatch(setEulaStatus({ value }));
  }

  acceptEULA(returnTo?: string): void {
    // this.setLoading(true);
    this.store$.dispatch(acceptEULA({ value: returnTo }));
  }

  navigateTo(path: string): void {
    this.store$.dispatch(navigateTo({ path: path }));
  }

  getEulaConfig(): void {
    this.store$.dispatch(getEulaConfig());
  }

  navigateToError(apiError: ApiError): void {
    this.store$.dispatch(navigateToError({ payload: apiError }));
  }

  setUserLoggedIn(userProfile: UserProfile): void {
    this.store$.dispatch(userLoginComplete({ payload: userProfile }));
  }

  setUserLoginFailed(loginError: LoginError): void {
    this.store$.dispatch(userLoginFailed({ payload: loginError }));
  }

  extendSession(): void {
    this.store$.dispatch(extendSession());
  }

  logoutSession(): void {
    this.store$.dispatch(logoutSession());
  }

  setExtendSession(value: boolean): void {
    this.store$.dispatch(setExtendSession({ value }));
  }

  setLogoutSession(value: boolean): void {
    this.store$.dispatch(setLogoutSession({ value }));
  }

  setInvitationId(payload: string): void {
    this.store$.dispatch(setInvitationIdSuccess({ payload }));
  }

  setHelpMenuToOpen(value: NavItem): void {
    this.store$.dispatch(setHelpMenuToOpen({ value }));
  }
}
