/** TO DO: fix the rule */
/* eslint-disable @ngrx/on-function-explicit-return-type */
import { Action, createReducer, on } from '@ngrx/store';
import {
  clearOrgEntitlementsRefresh,
  clearUserEntitlementsGridRefresh,
  clearUserRolesGridRefresh,
  createTenantSuccess,
  customProvisioningCompleted,
  getUserPreferencesSuccess,
  leaveCustomProvisioning,
  noLastAccessedTenant,
  refreshOrgEntitlements,
  refreshUserEntitlementsGrid,
  refreshUserRolesGrid,
  resetTenant,
  setAllTenantResourceRolesSuccess,
  setCurrentLanguage,
  setEditUserDetails,
  setFavoriteAppsSuccess,
  setNoSuggestedTenantByCodeFound,
  setServicesVisibilitySuccess,
  setSuggestedTenantByCodeSuccess,
  setTenantCreated,
  setTenantDetails,
  setTenantEffectiveRoles,
  setTenantId,
  setTenantLogo,
  setTenantServices,
  setTenantStatus,
  setTenantUsers,
  setTenantUtilityTokens,
  setUserId,
  setUserResourcesSuccess,
  setUserRolesForTenantSuccess,
  switchTenant,
  updateTenantNameSuccess,
  userData,
  waitForCustomProvisioningComplete,
} from './user-data.action';

import { ITenantDetail, UserPreferences, UserRole } from '@ra-state';

export const userDataInitialState: UserPreferences = {
  businessPartnerId: '',
  company: '',
  email: '',
  eulaAcceptanceRequired: false,
  eulaContentUrl: '',
  eulaVersion: '',
  firstName: '',
  lastName: '',
  location: '',
  name: '',
  notificationCounts: { totalNotifications: 0, unreadNotifications: 0 },
  notifications: [],
  tenants: [],
  newTenantCreated: false,
  services: [],
  servicesVisibility: [],
  preferences: {
    disableTokenNotification: false,
    language: 'en-US',
  },
  hasOrgEmailProvider: false,
  noLastAccessedTenant: false,
  refreshUserEntitlementsGrid: false,
  refreshOrgEntitlementsGrid: false,
  refreshUserRolesGrid: false,
};

const psetUserData = createReducer(
  userDataInitialState,
  on(userData, (state: UserPreferences, { value }) => ({
    ...state,
    businessPartnerId: value.businessPartnerId,
    company: value.company,
    email: value.email,
    eulaAcceptanceRequired: value.eulaAcceptanceRequired,
    eulaContentUrl: value.eulaContentUrl,
    eulaVersion: value.eulaVersion,
    firstName: value.firstName,
    lastName: value.lastName,
    location: value.location,
    name: value.name,
    tenants: value.tenants,
    hasOrgEmailProvider: value.hasOrgEmailProvider,
  })),
  on(setUserId, (state: UserPreferences, { payload }) => ({
    ...state,
    userId: payload,
  })),
  on(setCurrentLanguage, (state: UserPreferences, { preferredLanguage }) => ({
    ...state,
    preferences: { ...state.preferences, language: preferredLanguage },
  })),
  on(setTenantId, (state: UserPreferences, { value }) => ({
    ...state,
    currentTenantId: value,
  })),
  on(createTenantSuccess, (state: UserPreferences) => ({
    ...state,
    newTenantCreated: true,
  })),
  on(setTenantCreated, (state: UserPreferences, { value }) => ({
    ...state,
    newTenantCreated: value,
  })),
  on(setTenantDetails, (state: UserPreferences, { payload }) => ({
    ...state,
    currentTenant: payload,
  })),
  on(resetTenant, (state: UserPreferences) => ({
    ...state,
    currentTenant: undefined,
  })),
  on(getUserPreferencesSuccess, (state: UserPreferences, { value }) => ({
    ...state,
    accessibleTenants: value.accessibleTenants,
    businessPartnerId: value.businessPartnerId,
    company: value.company,
    email: value.email,
    eulaAcceptanceRequired: value.eulaAcceptanceRequired,
    eulaContentUrl: value.eulaContentUrl,
    eulaVersion: value.eulaVersion,
    firstName: value.firstName,
    lastName: value.lastName,
    location: value.location,
    name: value.name,
    preferences: {
      ...state.preferences,
      lastAccessedTenantId: value.preferences?.lastAccessedTenantId,
      disableTokenNotification:
        value.preferences?.disableTokenNotification || state.preferences?.disableTokenNotification,
      favoriteApps: value.preferences?.favoriteApps,
      language: value.preferences?.language,
    },
    hasOrgEmailProvider: value.hasOrgEmailProvider,
  })),
  on(setTenantEffectiveRoles, (state: UserPreferences, { effectiveRoles, tenantRole }) => ({
    ...state,
    effectiveRoles: effectiveRoles,
    currentTenantContext: {
      id: tenantRole.tenantId,
      name: tenantRole.resourceName,
      role: tenantRole?.role,
    },
  })),
  on(setAllTenantResourceRolesSuccess, (state: UserPreferences, { payload }) => ({
    ...state,
    resourceRoles: payload,
  })),
  on(setUserRolesForTenantSuccess, (state: UserPreferences, { payload }) => ({
    ...state,
    userResourceRoles: payload,
  })),
  on(setUserResourcesSuccess, (state: UserPreferences, { payload }) => ({
    ...state,
    resources: payload,
  })),
  on(
    setTenantUsers,
    (state: UserPreferences, { selectedTenantUsers }): UserPreferences => ({
      ...state,
      selectedTenantUsers,
    }),
  ),
  on(setSuggestedTenantByCodeSuccess, (state: UserPreferences, { payload }) => ({
    ...state,
    suggestedTenant: payload,
  })),
  on(setNoSuggestedTenantByCodeFound, (state: UserPreferences, { payload }) => ({
    ...state,
    noSuggestedTenant: payload,
  })),
  on(setEditUserDetails, (state: UserPreferences, { payload }) => ({
    ...state,
    editUser: payload as UserRole,
  })),
  on(updateTenantNameSuccess, (state: UserPreferences, { value }) => ({
    ...state,
    updatedTenantName: value,
  })),
  // on(joinTenantSuccess, (state: UserPreferences) => ({
  //   ...state,
  //   showJoinSuccessInlineMessage: true,
  // })),
  on(setServicesVisibilitySuccess, (state: UserPreferences, { payload }) => ({
    ...state,
    servicesVisibility: payload,
  })),
  on(setTenantServices, (state: UserPreferences, { payload }) => ({
    ...state,
    services: payload,
  })),
  on(setTenantUtilityTokens, (state: UserPreferences, { payload }) => ({
    ...state,
    tenantUtilityTokens: payload,
  })),
  on(waitForCustomProvisioningComplete, (state: UserPreferences, { payload }) => ({
    ...state,
    currentCustomProvisioningData: payload,
    exitFromProvisioning: false,
  })),
  on(customProvisioningCompleted, leaveCustomProvisioning, (state: UserPreferences) => ({
    ...state,
    currentCustomProvisioningData: undefined,
    exitFromProvisioning: true,
  })),
  on(setFavoriteAppsSuccess, (state: UserPreferences, { favoriteApps }) => ({
    ...state,
    preferences: {
      ...state.preferences,
      favoriteApps: favoriteApps,
    },
  })),
  on(setTenantLogo, (state: UserPreferences, { value }) => ({
    ...state,
    currentTenant: { ...state.currentTenant, logoImage: value } as ITenantDetail,
  })),
  on(setTenantStatus, (state: UserPreferences, { tenantStatus }) => ({
    ...state,
    currentTenant: { ...state.currentTenant, tenantStatus } as ITenantDetail,
  })),
  on(noLastAccessedTenant, (state: UserPreferences, { value }) => ({ ...state, noLastAccessedTenant: value })),
  on(switchTenant, (state: UserPreferences) => ({ ...state, noLastAccessedTenant: false })),
  on(refreshOrgEntitlements, (state: UserPreferences) => ({ ...state, refreshOrgEntitlementsGrid: true })),
  on(clearOrgEntitlementsRefresh, (state: UserPreferences) => ({ ...state, refreshOrgEntitlementsGrid: false })),
  on(refreshUserEntitlementsGrid, (state: UserPreferences) => ({ ...state, refreshUserEntitlementsGrid: true })),
  on(clearUserEntitlementsGridRefresh, (state: UserPreferences) => ({ ...state, refreshUserEntitlementsGrid: false })),
  on(refreshUserRolesGrid, (state: UserPreferences) => ({ ...state, refreshUserRolesGrid: true })),
  on(clearUserRolesGridRefresh, (state: UserPreferences) => ({ ...state, refreshUserRolesGrid: false })),
);

export function setUserData(state: UserPreferences, action: Action): UserPreferences {
  return psetUserData(state, action);
}
