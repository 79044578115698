import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { EntitlementStatus, TrialLinkStatus, UserType } from '@ra-state';
import { ITooltipConfig, TooltipPosition } from '@ra-web-tech-ui-toolkit/components';

@Component({
  selector: 'app-ag-allocate-btn',
  templateUrl: './ag-allocate-btn.component.html',
  styleUrls: ['./ag-allocate-btn.component.scss'],
})
export class AgAllocateBtnComponent implements ICellRendererAngularComp {
  readonly tooltipConfig: ITooltipConfig = { position: TooltipPosition.Top, maxWidth: 400 };
  readonly tooltipConfigNewUser: ITooltipConfig = { position: TooltipPosition.Left, maxWidth: 200 };
  readonly tooltipConfigServiceUser: ITooltipConfig = { position: TooltipPosition.Left, maxWidth: 215 };
  ENTITLEMENT_STATUS = EntitlementStatus;
  TRIAL_LINK_STATUS = TrialLinkStatus;
  personalTenantUserToolTip: string = 'To allocate your entitlements, create or join an organization.';
  personalTenantUserTrialToolTip: string = 'To allocate your trials, create or join an organization.';
  navigatorOrContributorToolTip: string =
    'You don’t have permission to allocate to this organization. Contact your administrator.';
  navigatorOrContributorTrialToolTip: string =
    'You don’t have sufficient permissions to view and allocate trials to this organization.';
  private params: any;
  isTrial: boolean;

  refresh(_params: ICellRendererParams): boolean {
    return true;
  }
  agInit(params: any): void {
    this.params = params;
    this.isTrial = this.params['isTrial'] || false;
  }

  btnClickedHandler(_event?: any): void {
    this.params.clicked(this.params.data);
  }

  get Status(): EntitlementStatus {
    return this.params?.data.status;
  }

  get TrialLinkStatus(): TrialLinkStatus {
    return this.params?.data.status;
  }

  get UserType(): UserType {
    return this.params?.context.componentParent.userType;
  }

  get Disabled(): boolean {
    return this.UserType === UserType.PersonalTenantUser || this.UserType === UserType.NavigatorOrContributor;
  }

  get AllocateToolTip(): string {
    if (this.UserType === UserType.PersonalTenantUser) {
      return this.isTrial ? this.personalTenantUserTrialToolTip : this.personalTenantUserToolTip;
    } else if (this.UserType === UserType.NavigatorOrContributor) {
      return this.isTrial ? this.navigatorOrContributorTrialToolTip : this.navigatorOrContributorToolTip;
    } else {
      return '';
    }
  }

  get AllocateTooltipConfig(): ITooltipConfig {
    if (this.UserType === UserType.PersonalTenantUser) {
      return this.tooltipConfigNewUser;
    } else if (this.UserType === UserType.NavigatorOrContributor) {
      return this.tooltipConfigServiceUser;
    } else {
      return { position: TooltipPosition.Left, maxWidth: 200 };
    }
  }
}
