/* eslint-disable sort-keys */
import { Injectable } from '@angular/core';
import { defaultErrorHandlers } from '@app/v2/shared/utils';
import { ControlPageFacade } from '@ra-state';
import { ConfigService, CoreConfiguration } from '@rockwell-automation-inc/service';
import { AppInsightsMonitoringService } from './app-insights-monitoring.service';
import {
  CommandRequest,
  DomainEventName,
  DomainEvents,
  EventPredicate,
  IDomainUpdateRequestBuilderOptions,
} from './command-request.service';
import { DataService } from './data.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class CommandRequestBuilderService {
  constructor(
    private configService: ConfigService<CoreConfiguration>,
    private logger: LoggerService,
    private dataService: DataService,
    private controlPageFacade: ControlPageFacade,
    private appInsightsMonitoringService: AppInsightsMonitoringService,
  ) {}

  // TO DO: define retuning type
  new(
    apiPath: string,
    method: 'POST' | 'DELETE' | 'PUT',
    domainEvent: EventPredicate | DomainEventName | DomainEvents,
    options: IDomainUpdateRequestBuilderOptions = {} as IDomainUpdateRequestBuilderOptions,
  ): CommandRequest {
    const apiEndPoint = this.configService.config.csApiBaseUrl + apiPath;
    this.logger.log('creating CommandRequestService instance for:  ', apiEndPoint);
    return CommandRequest.create(
      apiEndPoint,
      method,
      domainEvent,
      options,
      this.dataService,
      defaultErrorHandlers,
      this.logger,
      this.controlPageFacade,
      this.appInsightsMonitoringService,
    );
  }
}
