import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoggerService } from '@servicesV2/logger.service';
import { DateTime } from 'luxon';
import { delayWhen, distinctUntilChanged, filter, map, Observable, timer } from 'rxjs';
import { AppState, FeatureFlag, FlagValue, IMaintenanceWindow } from '../lemans-app.model';
import { selectFlags } from './feature-flags.selectors';

// TODO: only used in test directly - isn't there a better way to hide this class
export class MaintenanceWindow implements IMaintenanceWindow {
  public static Inactive: IMaintenanceWindow = Object.freeze({
    from: DateTime.fromMillis(0),
    duration: 0,
    to: DateTime.fromMillis(0),
    isActive: false,
    startsWithinDays: (_days: number): boolean => false,
  });
  from: DateTime;
  duration: number;
  public static create(json: string, logger?: LoggerService): IMaintenanceWindow {
    try {
      const obj = (json && JSON.parse(json)) || {};
      if (Object.keys(obj).length === 0) {
        return MaintenanceWindow.Inactive;
      }
      return new MaintenanceWindow(obj, logger);
    } catch (ex) {
      logger?.warn('Failed to parse MaintenanceWindow from flag value; Maintenance Window not activated', ex, json);
      return MaintenanceWindow.Inactive;
    }
  }
  private constructor(obj: any, logger?: LoggerService) {
    this.duration = parseInt(obj.duration) || 0;
    this.from = DateTime.fromISO(obj.from);
    if (!this.from.isValid) {
      logger?.error('Failed to parse from date - ', this.from.invalidReason, this.from.invalidExplanation);
      this.from = DateTime.fromMillis(0);
    }
  }

  get to(): DateTime {
    return this.from.plus({ minutes: this.duration });
  }
  get isActive(): boolean {
    const now = DateTime.now();
    return this.duration > 0 && now >= this.from && now <= this.from.plus({ minutes: this.duration });
  }
  startsWithinDays(days: number): boolean {
    const now = DateTime.now();
    return this.duration > 0 && now >= this.from.minus({ days: days }) && now <= this.from;
  }
}

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsFacade {
  flags$ = this.store$.select(selectFlags);
  // maintenanceWindow$ = this.store$.select(selectMaintenanceWindow).pipe(distinctUntilChanged());

  constructor(
    private store$: Store<AppState>,
    private logger: LoggerService,
  ) {}

  getFlagValue$(flag: FeatureFlag): Observable<FlagValue> {
    return this.flags$.pipe(
      map((flags) => (flags ? flags[flag] : undefined)),
      distinctUntilChanged(),
    );
  }

  maintenanceWindow$: Observable<IMaintenanceWindow> = this.getFlagValue$('maintenance_window').pipe(
    map((json: string) => MaintenanceWindow.create(json, this.logger)),
    distinctUntilChanged(),
  );

  /// sets up a delayed observable that triggers at the end time if the mw was active.
  maintenanceWindowCompleted$: Observable<any> = this.maintenanceWindow$.pipe(
    filter((mw) => mw.isActive),
    delayWhen((mw: IMaintenanceWindow, _idx) => timer(mw.to.toJSDate())),
  );
}
