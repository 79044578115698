import { Action, createReducer, on } from '@ngrx/store';
import { Trials } from '../lemans-app.model';
import { clearTrialGridRefresh, refreshTrialGrid, setTrialBundles, setTrialReservationInfo } from './trials.actions';

export const trialsInitialState: Trials = {
  bundles: [
    {
      id: '',
      name: '',
      description: '',
      credits: '',
      products: [],
    },
  ],
  refreshGrid: false,
};
const setTrialsData = createReducer(
  trialsInitialState,
  on(
    setTrialReservationInfo,
    (state: Trials, { payload }): Trials => ({
      ...state,
      reservation: payload,
    }),
  ),
  on(
    setTrialBundles,
    (state: Trials, { payload }): Trials => ({
      ...state,
      bundles: payload,
    }),
  ),
  on(
    refreshTrialGrid,
    (state: Trials): Trials => ({
      ...state,
      refreshGrid: true,
    }),
  ),
  on(
    clearTrialGridRefresh,
    (state: Trials): Trials => ({
      ...state,
      refreshGrid: false,
    }),
  ),
);

export function trialsReducer(state: Trials, action: Action): Trials {
  return setTrialsData(state, action);
}
