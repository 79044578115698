import { AgGridModule } from '@ag-grid-community/angular';
import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Inject, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { CoreModule } from '@core/core.module';
import { environment } from '@environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  ControlPageEffects,
  CustomSerializer,
  GainsightEffects,
  initApplication,
  ModalEffects,
  reducers,
  SignalREffects,
  SnackBarEffects,
  UserDataEffects,
} from '@ra-state';
import { LayoutModule, MultiTranslateHttpLoader, TranslationAsset } from '@rockwell-automation-inc/layout';
import { ServiceModule } from '@rockwell-automation-inc/service';
import { AppInsightErrorHandlerService } from '@servicesV2/app-insight-error-handler.service';
import { LoggerService } from '@servicesV2/logger.service';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SwiperModule } from 'swiper/angular';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { AccessRequestsEffects } from './+state/access-requests';
import { FeatureFlagsEffects } from './+state/feature-flags';
import { InvitationEffects } from './+state/invitation';
import { TrialsEffects } from './+state/trials/trials.effects';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { MaintenanceWindowInterceptor } from './interceptors/maintenance-window.interceptor';
import { RequestIdInterceptor } from './interceptors/reqId.interceptor';
import { TenantStatusInterceptor } from './interceptors/tenant-status.interceptor';
import { DemoMaterialModule } from './material.module';
import { AuthenticationModule } from './v2/auth/auth.module';
import { ErrorLoginModule } from './v2/error-login/error-login.module';
import { ShellModule } from './v2/shell/shell.module';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): MultiTranslateHttpLoader {
  const translationResouceList: TranslationAsset[] = [{ path: './assets/i18n/' }];
  return new MultiTranslateHttpLoader(http, translationResouceList);
}
@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BreadcrumbModule,
    SwiperModule,
    NgScrollbarModule,
    AgGridModule,
    StoreModule.forRoot(reducers),
    StoreDevtoolsModule.instrument({ logOnly: environment.production, maxAge: 25 }),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
    }),
    EffectsModule.forRoot([
      ControlPageEffects,
      GainsightEffects,
      UserDataEffects,
      SignalREffects,
      SnackBarEffects,
      ModalEffects,
      FeatureFlagsEffects,
      InvitationEffects,
      AccessRequestsEffects,
      TrialsEffects,
    ]),
    AuthenticationModule,
    ShellModule,
    CoreModule,
    ErrorLoginModule,
    AuthModule.forRoot(),
    LayoutModule,
    ServiceModule.forRoot(environment.appConfiguration),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'en-US',
      useDefaultLang: true,
    }),
  ],
  providers: [
    {
      deps: [[new Inject(Store)], LoggerService],
      multi: true,
      provide: APP_INITIALIZER,
      useFactory: initApplication,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { multi: true, provide: HTTP_INTERCEPTORS, useClass: RequestIdInterceptor },
    { multi: true, provide: HTTP_INTERCEPTORS, useClass: MaintenanceWindowInterceptor },
    { multi: true, provide: HTTP_INTERCEPTORS, useClass: TenantStatusInterceptor },
    { multi: true, provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor },
    DatePipe,
    { provide: ErrorHandler, useClass: AppInsightErrorHandlerService },
  ],
})
export class AppModule {}
