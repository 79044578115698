/** TO DO: fix the rule */
/* eslint-disable @ngrx/on-function-explicit-return-type */
import { Action, createReducer, on } from '@ngrx/store';
import { AppFlow, PageControl } from '../lemans-app.model';
import { showTenantArchiveBanner } from '../user-data';

import {
  baseURLSuccess,
  getApplications,
  getEnabledApplications,
  loading,
  setAppIntialRoute,
  setErrorContext,
  setEulaConfigSuccess,
  setEulaContentSuccess,
  setEulaStatus,
  setExtendSession,
  setHelpMenuToOpen,
  setInvitationIdSuccess,
  setLogoutSession,
  setProductCatalogSuccess,
  setSessionTimedOut,
  setUserActive,
  setUserNotFound,
  setVersions,
  userLoginComplete,
} from './control-page.actions';

export const initialState: PageControl = {
  isBaseURLSet: false,
  isEulaAccepted: false,
  isLoading: 0,
  isUserNotFound: false,
  isUserActive: false,
  sessionTimedOut: false,
  extendSession: false,
  logoutSession: false,
  // TODO: Possibly dangerous?, why doesn't directly initializing this work?
  applications: [],
  appsMap: {},
  enabledApplications: [],
  helpMenuToOpen: { id: '', displayName: '' },
  routeState: {
    flow: AppFlow.Dashboard,
  },
  showArchiveBanner: false,
};

const pageActions = createReducer(
  initialState,
  on(loading, (state: PageControl, { value }) => {
    const delta = value ? 1 : -1;
    const isLoading = state.isLoading + delta;
    if (isLoading < 0) {
      // eslint-disable-next-line no-console
      console.warn('reducer: isLoading is going negative!!!');
    }
    return { ...state, isLoading: isLoading };
  }),
  on(setEulaStatus, (state: PageControl, { value }) => ({ ...state, isEulaAccepted: value })),
  on(setUserNotFound, (state: PageControl, { value }) => ({ ...state, isUserNotFound: value })),
  on(getApplications, (state: PageControl, { payload }) => {
    const appsMap = {};
    payload.map((app) => (appsMap[app.appId] = app));
    return {
      ...state,
      appsMap: appsMap,
      applications: payload,
    };
  }),
  on(getEnabledApplications, (state: PageControl, { payload }) => ({
    ...state,
    enabledApplications: payload,
  })),
  on(baseURLSuccess, (state: PageControl, { value }) => ({ ...state, isBaseURLSet: value })),
  on(setEulaConfigSuccess, (state: PageControl, { payload }) => ({ ...state, eulaConfig: payload })),
  on(setEulaContentSuccess, (state: PageControl, { value }) => ({ ...state, eulaContent: value })),
  on(setErrorContext, (state: PageControl, { payload }) => ({ ...state, apiError: payload })),
  on(setProductCatalogSuccess, (state: PageControl, { payload }) => ({ ...state, catalogInfo: payload })),
  on(setVersions, (state: PageControl, { value }) => ({ ...state, versions: value })),
  on(setUserActive, (state: PageControl, { value }) => ({ ...state, isUserActive: value })),
  on(setSessionTimedOut, (state: PageControl, { value }) => ({ ...state, sessionTimedOut: value })),
  on(setExtendSession, (state: PageControl, { value }) => ({ ...state, extendSession: value })),
  on(setLogoutSession, (state: PageControl, { value }) => ({ ...state, logoutSession: value })),
  on(setInvitationIdSuccess, (state: PageControl, { payload }) => ({ ...state, invitationId: payload })),
  on(setAppIntialRoute, (state: PageControl, { payload }) => ({ ...state, routeState: payload })),
  on(setHelpMenuToOpen, (state: PageControl, { value }) => ({ ...state, helpMenuToOpen: value })),
  on(userLoginComplete, (state: PageControl, { payload }) => ({ ...state, loggedInUserProfile: payload })),
  on(showTenantArchiveBanner, (state: PageControl, { show }) => ({
    ...state,
    showArchiveBanner: show,
  })),
);

export function controlPageReducer(state: PageControl, action: Action): PageControl {
  return pageActions(state, action);
}
