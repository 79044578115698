<span>{{ params.value | customDate: 'mediumDate' : preferredLanguage }} </span>
<span
  *ngIf="
    isExpiringSoon() &&
    (params.data.status === LINK_STATUS.REDEEMED || params.data.status === TRIAL_STATUS.ACTIVE.toLowerCase())
  "
  class="expire-soon"
  [raUiBadge]="'value'"
  [badgeConfig]="badgeConfig"
  >Expiring Soon</span
>
