<section class="service-cell">
  <div class="body-2-bold ellipsis" *ngIf="params">
    <span
      *ngIf="checkIsNew()"
      class="inline-display"
      raUiBadge="."
      [badgeConfig]="{
        position: BadgePosition.Before,
        color: BADGE_STATUS_COLORS.Information,
        size: BadgeSize.Indicator,
      }"
    ></span>
    {{ params.data?.campaignBundleName }}
  </div>
  <div class="body-2 ellipsis" [ngStyle]="{ 'padding-bottom': toggle ? '10px' : '' }">
    <div *ngIf="toggle && params; else toggleElements">
      <div>Trial ID: {{ params.data.id }}</div>
      <div *ngIf="isAllocated">
        <div>Allocated on: {{ params.data.allocatedOn | customDate }}</div>
        <div>Allocated by: {{ params.data.allocatedByUserEmail }}</div>
      </div>
      <span class="toggle" (click)="toggleDetails()"
        >Details <i class="ra-icon-ide-sm-chevron-up align-middle"></i>
      </span>
    </div>
    <ng-template #toggleElements>
      <span class="toggle" (click)="toggleDetails()"
        >Details <i class="ra-icon-ide-sm-chevron-down align-middle"></i>
      </span>
    </ng-template>
  </div>
</section>
