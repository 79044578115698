import { Injectable } from '@angular/core';
import { IGainsightCustomEvent, IGainsightOrganizationContext, IGainsightUserIdentity } from '@ra-state';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class GainsightService {
  constructor(private logger: LoggerService) {}

  pushUserProfile(userIdentity: IGainsightUserIdentity): any {
    if (!('aptrinsic' in window)) {
      return;
    }
    try {
      const account = {
        id: userIdentity.domain,
        emailDomain: userIdentity.domain,
        raBusinessPartnerID: userIdentity.raBusinessPartnerID,
        name: userIdentity.company,
      };
      // @ts-ignore
      aptrinsic('identify', userIdentity, account);
    } catch (error) {
      this.logger.error(error);
    }
  }

  reset(): void {
    if (!('aptrinsic' in window)) {
      return;
    }
    try {
      // @ts-ignore
      aptrinsic('reset');
    } catch (error) {
      this.logger.error(error);
    }
  }

  setGlobalContext(context: IGainsightOrganizationContext): any {
    if (!('aptrinsic' in window)) {
      return;
    }
    try {
      // @ts-ignore
      aptrinsic('set', 'globalContext', context);
    } catch (error) {
      this.logger.error(error);
    }
  }

  pushCustomEvent({ eventName, eventPayload }: IGainsightCustomEvent): any {
    if (!('aptrinsic' in window)) {
      return;
    }
    try {
      // @ts-ignore
      aptrinsic('track', eventName, eventPayload);
    } catch (error) {
      this.logger.error(error);
    }
  }
}
