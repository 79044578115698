import { displayMessageAction } from '@app/v2/shared/utils';
import { ApiError } from '@core/api-error';
import { Action } from '@ngrx/store';
import { ApiEndPoints } from '@servicesV2/data.service';
import { unknownErrorHandler } from '@servicesV2/apierror-handlers.service';

export enum AccessRequestEvent {
  AccessRequestCreated = 'AccessRequestCreated',
  Approved = 'Approved',
  Deleted = 'Deleted',
}

export enum AccessRequestSendError {
  UserAlreadyHasAccessToSpecifiedResource = 'UserAlreadyHasAccessToSpecifiedResource',
}

export type AccessRequestSendErrorValue = keyof typeof AccessRequestSendError;

export interface SendAccessRequestError {
  errorCode: AccessRequestSendErrorValue;
}

export interface JoinTenant {
  resourceId: string;
  tenantId: string;
}

export function handleCreateAccessRequestErrors(apiError: ApiError, _body: any): Action[] {
  if (!apiError.Url.includes(ApiEndPoints.AccessRequest)) {
    throw new Error('Url mismatch: API url does not match invitations endpoint');
  }

  const errorData: SendAccessRequestError = apiError.ErrorData as any;

  if (errorData === null) {
    return [unknownErrorHandler()];
  }

  if (errorData.errorCode === AccessRequestSendError.UserAlreadyHasAccessToSpecifiedResource) {
    return [displayMessageAction('Your account already has access to the organization.')];
  } else {
    return [unknownErrorHandler()];
  }
}
