import { Action, createReducer, on } from '@ngrx/store';
import { InvitationActions, InvitationState } from './store';

const initialState: InvitationState = {
  inviteErrors: [],
  refreshGrid: false,
};

const psetInvitation = createReducer(
  initialState,
  on(InvitationActions.setInviteError, (state: InvitationState, { payload }): InvitationState => {
    const newState = { ...state, inviteErrors: [...state.inviteErrors, payload] };
    return newState;
  }),
  on(InvitationActions.resetInviteError, (state): InvitationState => {
    const newState = { ...state, inviteErrors: [] };
    return newState;
  }),
  on(
    InvitationActions.resendInvitationSuccess,
    InvitationActions.deleteInvitationSuccess,
    InvitationActions.createInvitationsSuccess,
    (state: InvitationState): InvitationState => {
      return { ...state, refreshGrid: true };
    },
  ),
  on(InvitationActions.resetInvitationGridRefresh, (state: InvitationState): InvitationState => {
    return { ...state, refreshGrid: false };
  }),
);

export function invitationsReducer(state: InvitationState, action: Action): InvitationState {
  return psetInvitation(state, action);
}
