import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, createAction, createReducer, createSelector, on, props } from '@ngrx/store';
import { displayMessage } from '@ra-state';
import { CommandRequestBuilderService } from '@servicesV2/command-request-builder.service';
import { ApiEndPoints } from '@servicesV2/data.service';
import { LoggerService } from '@servicesV2/logger.service';
import { Observable, combineLatest, forkJoin, of, switchMap } from 'rxjs';
import { AddResourceAccess, AppState, ICommandResponse } from '../lemans-app.model';
import { AccessRequestEvent, JoinTenant, handleCreateAccessRequestErrors } from './access-request.domain';

export type AccessRequestState = {
  refreshGrid: boolean;
  accessRequestCreated: boolean;
};
export const accessRequestState: AccessRequestState = {
  refreshGrid: false,
  accessRequestCreated: false,
};

export const joinTenant = createAction(
  '[APPROVE USER] Submit Request',
  props<{ payload: JoinTenant; isRedirectToDashboard: boolean }>(),
);
export const joinTenantSuccess = createAction('[APPROVE USER] Request Submitted');
export const approveUser = createAction(
  '[APPROVE USER] Approve User',
  props<{ payload: AddResourceAccess[]; id: string }>(),
);
export const approveUserSuccess = createAction('[APPROVE USER] Approve User Success');

export const dismissUser = createAction('[APPROVE USER] Dismiss User', props<{ value: string; tenantId: string }>());
export const dismissUserSuccess = createAction('[APPROVE USER] Dismiss User Success');
export const resetApproveUserGrid = createAction('[APPROVE USER] Reset Grid');
export const AccessRequestActions = {
  joinTenant,
  approveUser,
  dismissUser,
  resetGrid: resetApproveUserGrid,
};

export const selectJoinOrgSuccess = createSelector(
  (state: AppState) => state.accessRequests,
  (state: AccessRequestState) => state.accessRequestCreated,
);
export const selectRefreshGridData = createSelector(
  (state: AppState) => state.accessRequests,
  (state: AccessRequestState) => state.refreshGrid,
);

const accessRequestsReducerImpl = createReducer(
  accessRequestState,
  on(joinTenantSuccess, (state): AccessRequestState => {
    return { ...state, accessRequestCreated: true };
  }),
  on(approveUserSuccess, (state): AccessRequestState => {
    return { ...state, refreshGrid: !state.refreshGrid };
  }),
  on(dismissUserSuccess, (state): AccessRequestState => {
    return { ...state, refreshGrid: !state.refreshGrid };
  }),
  on(resetApproveUserGrid, (state): AccessRequestState => {
    return { ...state, refreshGrid: false };
  }),
);
export function accessRequestReducer(state: AccessRequestState, action: Action): AccessRequestState {
  return accessRequestsReducerImpl(state, action);
}

@Injectable({
  providedIn: 'root',
})
export class AccessRequestsFacade {
  constructor(private store$: Store<AppState>) {}

  joinOrgSuccess$ = this.store$.select(selectJoinOrgSuccess);
  refresh$ = this.store$.select(selectRefreshGridData);
  approveUser(payload: AddResourceAccess[], id: string): void {
    this.store$.dispatch(approveUser({ id: id, payload: payload }));
  }
  dismissUser(userId: string, tenantId: string): void {
    this.store$.dispatch(dismissUser({ tenantId: tenantId, value: userId }));
  }

  joinTenant(tenant: JoinTenant, isRedirectToDashboard: boolean = true): void {
    this.store$.dispatch(joinTenant({ payload: tenant, isRedirectToDashboard }));
  }
}

@Injectable({
  providedIn: 'root',
})
export class AccessRequestsEffects {
  approveUser$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(approveUser),
      switchMap((tenantAccess) => {
        const approvalReqs = tenantAccess.payload.map((dataMapped) => {
          return this.commandRequestBuilderService
            .new(ApiEndPoints.AccessRequest + '/' + tenantAccess.id + '/approve', 'POST', AccessRequestEvent.Approved)
            .withTenantHeader(dataMapped.resourceId)
            .withBody({ role: dataMapped.role })
            .withWaitOn200Ok()
            .invoke$();
        });

        return forkJoin(approvalReqs);
      }),
      switchMap((responses) => {
        const errorResponses: ICommandResponse[] = [];
        const successResponses: ICommandResponse[] = [];
        responses.forEach((resp) => {
          if (Boolean(resp.error)) {
            errorResponses.push(resp);
          } else {
            successResponses.push(resp);
          }
        });

        if (successResponses.length) {
          return [
            approveUserSuccess(),
            displayMessage({
              payload: {
                message: 'User has been approved.',
                type: 'Success',
              },
            }),
            resetApproveUserGrid(),
          ];
        }

        let actions: Action[] = [];
        errorResponses.forEach((errorResp) => {
          if (errorResp.errorActions) {
            actions = actions.concat(errorResp.errorActions);
          }
        });
        return actions;
      }),
    );
  });

  dismissUser$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(dismissUser),
      switchMap((removeData) => {
        return this.commandRequestBuilderService
          .new(ApiEndPoints.AccessRequest + '/' + removeData.value, 'DELETE', 'Deleted')
          .withTenantHeader(removeData.tenantId)
          .withWaitOn200Ok()
          .invoke$();
      }),
      switchMap(() => [
        dismissUserSuccess(),
        displayMessage({
          payload: {
            message: 'Access request dismissed.',
            type: 'Info',
          },
        }),
        resetApproveUserGrid(),
      ]),
    );
  });

  joinTenant$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(joinTenant),
      switchMap((payload) => {
        const commandRequest = this.commandRequestBuilderService
          .new(`${ApiEndPoints.AccessRequest}`, 'POST', AccessRequestEvent.AccessRequestCreated)
          .withBody(payload.payload)
          .withErrorHandler({
            [HttpStatusCode.BadRequest]: handleCreateAccessRequestErrors,
          });
        return combineLatest([commandRequest.invoke$(), of(payload)]);
      }),
      switchMap(([response, payload]) => {
        if (response.error && response.errorActions) {
          return response.errorActions;
        }

        if (response && payload.isRedirectToDashboard !== false) {
          this.router.navigate([this.DASHBOARD_PATH]);
        }
        return [
          joinTenantSuccess(),
          displayMessage({
            payload: {
              message:
                'Request Sent Successfully! You will be notified once the owner of the organization approves your request',
              type: 'Success',
            },
          }),
        ];
      }),
    );
  });
  logger: LoggerService;
  private readonly DASHBOARD_PATH = '/dashboard';
  constructor(
    private readonly actions$: Actions,
    private readonly commandRequestBuilderService: CommandRequestBuilderService,
    private readonly router: Router,
    logger: LoggerService,
  ) {
    this.logger = logger.withContext('AccessRequestsEffects');
  }
}
