import {
  HttpContextToken,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Inject, Injectable } from '@angular/core';
import { ControlPageFacade } from '@ra-state';
import { SKIP_GLOBAL_SPINNER } from '@rockwell-automation-inc/service';
import { LoggerService } from '@servicesV2/logger.service';
import { finalize, Observable, tap } from 'rxjs';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private controlPageFacade: ControlPageFacade) {}

  logger = inject(LoggerService).withContext(LoaderInterceptor);

  @Inject('skipGlobalSpinner') skipTokens: HttpContextToken<boolean>[] = [SKIP_GLOBAL_SPINNER];

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req).pipe(
      tap((ev: HttpEvent<any>) => {
        if (this.skipTokens.some((it) => req.context.get(it))) {
          this.logger.debug('Spin#', req.method, req.url);
          return;
        }
        if (ev.type === HttpEventType.Sent) {
          this.controlPageFacade.setLoading(true);
          this.logger.debug('Spin+', req.method, req.url);
        }
      }),
      finalize(() => {
        if (this.skipTokens.some((it) => req.context.get(it))) {
          this.logger.debug('Spin#', req.method, req.url);
          return;
        }
        this.controlPageFacade.setLoading(false);
        this.logger.debug('Spin-', req.method, req.url);
      }),
    );
  }
}
