import { Injectable } from '@angular/core';
import { AlertModalConfig, CatalogModalConfig, ConfirmModalConfig } from '@core/modal/modal.service';
import { Store } from '@ngrx/store';
import { AppState } from '../lemans-app.model';
import { openAlertDialog, openCatalogDialog, openConfirmDialog, openWebTechDialog } from './modal.action';
import { DialogComponent, DialogService, IDialogConfig, NotificationType } from '@ra-web-tech-ui-toolkit/components';
import { MatDialogRef } from '@angular/material/dialog';
@Injectable({
  providedIn: 'root',
})
export class ModalFacade {
  constructor(
    private store$: Store<AppState>,
    private dialogService: DialogService,
  ) {}

  openConfirmDialog(content: ConfirmModalConfig): void {
    this.store$.dispatch(openConfirmDialog({ payload: content }));
  }

  openAlertDialog(content: AlertModalConfig): void {
    this.store$.dispatch(openAlertDialog({ payload: content }));
  }

  openCatalogDialog(content: CatalogModalConfig): void {
    this.store$.dispatch(openCatalogDialog({ payload: content }));
  }

  openWebTechDialog(config: IDialogConfig, type?: NotificationType): void {
    this.store$.dispatch(openWebTechDialog({ config: config, notificationType: type }));
  }

  openDialog(config: IDialogConfig, type?: NotificationType): MatDialogRef<DialogComponent> {
    switch (type) {
      case NotificationType.Success:
        return this.dialogService.openSuccessDialog(config);
      case NotificationType.Info:
        return this.dialogService.openInfoDialog(config);
      case NotificationType.Warning:
        return this.dialogService.openWarningDialog(config);
      case NotificationType.Error:
        return this.dialogService.openErrorDialog(config);
      default:
        return this.dialogService.openDialog(config);
    }
  }
}
