/* eslint-disable sort-keys */
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { AccessRequestEvent } from '@app/+state/access-requests';
import { ApiError } from '@core/api-error';
import { Action } from '@ngrx/store';
import { ControlPageFacade, ICommandResponse, IDomainMessage, SignalRFacade } from '@ra-state';
import {
  Observable,
  TimeoutError,
  catchError,
  filter,
  finalize,
  map,
  of,
  skipWhile,
  switchMap,
  take,
  tap,
  throwError,
  timeout,
} from 'rxjs';
import { AppInsightsMonitoringService } from './app-insights-monitoring.service';
import { DataService } from './data.service';
import { LoggerService } from './logger.service';

type CommandRequestOptions = {
  body: any;
  headers: HttpHeaders;
  observe: 'response';
};
type MethodType = 'POST' | 'DELETE' | 'PUT';

export class CommandRequest {
  get Method(): MethodType {
    return this.method;
  }

  get ApiEndpoint(): string {
    return this.apiEndPoint;
  }

  get RequestOptions(): CommandRequestOptions {
    return {
      body: this.body,
      headers: this.headers,
      observe: 'response',
    };
  }

  get TimeOutInMillies(): number {
    return this.timeoutMillis;
  }

  public skipWaitingOnResponse(responseStatus): boolean {
    return !this.WaitOnSuccessResponsePredicate(responseStatus);
  }

  private apiEndPoint: string;
  private timeoutMillis: number;
  private event: EventPredicate | DomainEventName | DomainEvents;
  private headers: HttpHeaders;
  private body: any;
  private method: 'POST' | 'DELETE' | 'PUT';
  private errorHandlers: ErrorHandler;
  private logger: LoggerService;
  private controlPageFacade: ControlPageFacade;
  private WaitOnSuccessResponsePredicate: WaitOnSuccessResponsePredicate;
  private dataService: DataService;
  private httpClient: HttpClient;
  private signalRFacade: SignalRFacade;
  context: any = {};
  private appInsightsMonitoringService: AppInsightsMonitoringService;
  constructor(
    apiEndPoint,
    method,
    event,
    body,
    headers,
    timeoutMillis,
    waitOnSuccessResponsePredicate,
    dataService: DataService,
    defaultErrorHandlers: ErrorHandler,
    logger: LoggerService,
    controlPageFacade: ControlPageFacade,
    appInsightsMonitoringService: AppInsightsMonitoringService,
  ) {
    this.apiEndPoint = apiEndPoint;
    this.method = method;
    this.event = event;
    this.body = body;
    this.headers = headers;
    this.timeoutMillis = timeoutMillis;
    this.dataService = dataService;
    this.WaitOnSuccessResponsePredicate = waitOnSuccessResponsePredicate;
    this.errorHandlers = defaultErrorHandlers;
    this.logger = logger;
    this.controlPageFacade = controlPageFacade;
    this.appInsightsMonitoringService = appInsightsMonitoringService;
  }

  private static waitOnCreatedResponse(status: number): boolean {
    return status === 201;
  }

  static create(
    apiEndPoint: string,
    method: 'POST' | 'DELETE' | 'PUT',
    domainEvent: EventPredicate | string,
    options: IDomainUpdateRequestBuilderOptions = {} as IDomainUpdateRequestBuilderOptions,
    dataService: DataService,
    defaultErrorHandlers: ErrorHandler,
    logger: LoggerService,
    controlPageFacade: ControlPageFacade,
    appInsightsMonitoringService: AppInsightsMonitoringService,
  ): CommandRequest {
    const defaultOptions = {
      WaitOnSuccessResponsePredicate: CommandRequest.waitOnCreatedResponse,
      body: {},
      headers: new HttpHeaders(),
      timeoutSeconds: 60,
    };
    const targetOptions = Object.assign(defaultOptions, options);
    const timeoutMillis = targetOptions.timeoutSeconds * 1000;
    const waitOnSuccessResponsePredicate = targetOptions.WaitOnSuccessResponsePredicate;

    return new CommandRequest(
      apiEndPoint,
      method,
      domainEvent,
      targetOptions.body,
      targetOptions.headers,
      timeoutMillis,
      waitOnSuccessResponsePredicate,
      dataService,
      defaultErrorHandlers,
      logger,
      controlPageFacade,
      appInsightsMonitoringService,
    );
  }

  // TO DO: define retuning type
  withBody(body): this {
    this.body = body;
    return this;
  }

  withContext(context: any): this {
    this.context = context;
    return this;
  }
  withTenantHeader(tenantId: string): this {
    this.headers = this.headers.set('tenantid', tenantId);
    return this;
  }

  // do we need this?
  withCustomTimer(customtimer: string): this {
    this.headers = this.headers.set('customtimer', customtimer);
    return this;
  }

  withIdempotencyKey(idempotencyKey: string): this {
    this.headers = this.headers.set('idempotencyKey', idempotencyKey);
    return this;
  }

  withTimeout(timeoutInSeconds): this {
    this.timeoutMillis = timeoutInSeconds * 1000;
    return this;
  }

  withWaitOn201Created(): this {
    this.WaitOnSuccessResponsePredicate = CommandRequest.waitOnCreatedResponse;
    return this;
  }

  withWaitOn200Ok(): this {
    this.WaitOnSuccessResponsePredicate = (responseStatus: number): boolean => responseStatus === 200;
    return this;
  }

  withWaitOn202Accepted(): this {
    this.WaitOnSuccessResponsePredicate = (responseStatus: number): boolean => responseStatus === 202;
    return this;
  }

  withErrorHandler(errorHandlers: ErrorHandler): this {
    Object.assign(this.errorHandlers, errorHandlers);
    return this;
  }

  withHttpClient(httpClient: HttpClient): this {
    this.httpClient = httpClient;
    return this;
  }
  withSignalR(signalRFacade: SignalRFacade): this {
    this.signalRFacade = signalRFacade;
    return this;
  }

  invoke$<T = any>(): Observable<ICommandResponse<T>> {
    return of(true).pipe(
      tap(() => {
        this.logger.debug('Spinner CommandReq: +1');
        this.controlPageFacade.setLoading(true);
      }),
      switchMap(() =>
        this.dataService
          .configureCommandRequest(this)
          .httpClient.request<T>(this.Method, this.ApiEndpoint, this.RequestOptions),
      ),
      switchMap((response) => this.toDomainResponse$<T>(response)),
      catchError((error: unknown) => {
        const errorResponse = error as HttpErrorResponse;
        const apiError = new ApiError(errorResponse);
        const response: ICommandResponse<T> = {
          error: apiError,
          errorActions: this.errorHandlers[errorResponse.status](apiError, this.body, this.context),
        };
        this.appInsightsMonitoringService.trackCommandErrorRequest(error);
        return of(response);
      }),
      finalize(() => {
        this.logger.debug('Spinner CommandReq (Finalize): -1');
        this.controlPageFacade.setLoading(false);
      }),
    );
  }
  // TO DO: define retuning type
  waitOnResponse(fn: (status: number) => boolean): any {
    this.WaitOnSuccessResponsePredicate = fn;
    return this;
  }

  private toDomainResponse$<T>(response: HttpResponse<T>): Observable<ICommandResponse<T>> {
    if (this.skipWaitingOnResponse(response.status)) {
      return of({ response } as ICommandResponse<T>);
    }
    return this.signalRFacade.domainMessage$.pipe(
      filter((domainMessage) => {
        return domainMessage?.context.correlationId === response.headers.get('RequestId');
      }),
      skipWhile((domainMessage) => {
        this.logger.log('recd domain message', domainMessage);
        return this.notEventPredicateSatisfied(domainMessage as IDomainMessage);
      }),
      take(1),
      map((domainMessage) => {
        const domainUpdateResponse: ICommandResponse<T> = {
          message: domainMessage,
          response: response,
        };
        return domainUpdateResponse;
      }),
      timeout(this.TimeOutInMillies),
      catchError((error: unknown) => {
        if (error instanceof TimeoutError) {
          const error = new HttpErrorResponse({
            error: `Timeout for ${this}`,
            status: HttpStatusCode.GatewayTimeout,
          });
          this.logger.error(error);
          return throwError(() => error);
        }
        return throwError(() => error);
      }),
    );
  }

  public eventPredicateSatisfied = (domainMessage: IDomainMessage): boolean => {
    if (!domainMessage) {
      return false;
    }

    if (typeof this.event === 'string') {
      return domainMessage.type === this.event;
    } else if (typeof this.event === 'function') {
      return this.event(domainMessage);
    }
    return false;
  };

  public notEventPredicateSatisfied = (domainMessage: IDomainMessage): boolean => {
    return !this.eventPredicateSatisfied(domainMessage);
  };
}

export type WaitOnSuccessResponsePredicate = (status: number) => boolean;
export type EventPredicate = (domainMessage: IDomainMessage) => boolean;
export type ErrorHandler = Partial<Record<HttpStatusCode, (apiError: ApiError, body: any) => Action[]>>;
export interface IDomainUpdateRequestBuilderOptions {
  body: any;
  headers: HttpHeaders;
  timeoutSeconds: number;
  WaitOnSuccessResponsePredicate: WaitOnSuccessResponsePredicate;
}

export enum TenantEvent {
  TenantUpdated = 'TenantUpdated',
  TenantNameUpdated = 'TenantNameUpdated',
  ServiceAddedV2 = 'ServiceAddedV2',
  ServiceAdditionCancelled = 'ServiceAdditionCancelled',
  InviteCodeGenerated = 'InviteCodeGenerated',
  EntitlementAddedV2 = 'EntitlementAddedV2',
  ServiceVisibilityUpdated = 'ServiceVisibilityUpdated',
  TokenEntitlementAllocated = 'TokenEntitlementAllocated',
  TrialAllocationUserActionPending = 'TrialAllocationUserActionPending',
  Archived = 'Archived',
  Unarchived = 'Unarchived',
}
export enum UserEvent {
  UserEulaAccepted = 'UserEulaAccepted',
  UserRoleAssigned = 'UserRoleAssigned',
  UserRoleRevoked = 'UserRoleRevoked',
}
export enum InvitationEvent {
  InvitationCreated = 'InvitationCreated',
  InvitationReissuedV2 = 'InvitationReissuedV2',
  InvitationFinalized = 'InvitationFinalized',
  UserRoleUpdateErroredV2 = 'UserRoleUpdateErroredV2',
}
export enum EntitlementEvent {
  EntitlementConsumptionReversedV2 = 'EntitlementConsumptionReversedV2',
  EntitlementDeallocated = 'EntitlementDeallocated',
  EntitlementAddedV2 = 'EntitlementAddedV2',
}

export enum TrialEvent {
  TrialRedeemed = 'TrialRedeemed',
  TrialAllocated = 'TrialAllocated',
  TrialAllocationFailed = 'TrialAllocationFailed',
}

export type DomainEvents =
  | TenantEvent
  | UserEvent
  | InvitationEvent
  | EntitlementEvent
  | AccessRequestEvent
  | TrialEvent;
export type DomainEventName =
  | keyof typeof InvitationEvent
  | keyof typeof UserEvent
  | keyof typeof TenantEvent
  | keyof typeof EntitlementEvent
  | keyof typeof AccessRequestEvent;
// TODO: Move this const to models folder
//export const DomainEvents = { };
