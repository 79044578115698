import { AppErrorCode } from '@core/common-constants';
import { IDomainMessage } from '@ra-state';

export class DomainEvent<T = any> {
  private readonly errorCode: AppErrorCode;
  private readonly eventData: T;
  private readonly error: string;
  constructor(domainMessage?: IDomainMessage) {
    this.error = domainMessage?.data?.reason?.Item;
    this.eventData = domainMessage?.data as T;
  }
  get ErrorCode(): string {
    return this.ErrorCodeParts[0];
  }
  get ErrorCodeParts(): string[] {
    return this.error.split('#');
  }
  get EventData(): T {
    return this.eventData;
  }
}
