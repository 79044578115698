import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { AG_RENDER_BUTTON_OPTIONS } from '@app/v2/shared/ag-renderers/ag-grid.models';
import { AgTwoButtonConfig } from '@app/v2/shared/ag-renderers/ag-models';

@Component({
  selector: 'app-ag-invitation-action-button',
  templateUrl: './ag-invitation-action-button.component.html',
  styleUrls: ['./ag-invitation-action-button.component.scss'],
})
export class AgInvitationActionButtonComponent implements ICellRendererAngularComp {
  params: any;
  buttonsConfig: AgTwoButtonConfig;
  AG_RENDER_BUTTONS = AG_RENDER_BUTTON_OPTIONS;

  refresh(): boolean {
    return false;
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.buttonsConfig = this.params.data.buttonsConfig;
  }
  iconResendAction(action: string): void {
    if (this.buttonsConfig.btn1.enable) {
      this.params.agRenderIconClickHandler(action, this.params.data);
    }
  }
  iconDeleteAction(action: string): void {
    if (this.buttonsConfig.btn2.enable) {
      this.params.agRenderIconClickHandler(action, this.params.data);
    }
  }
}
