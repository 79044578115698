import { createSelector } from '@ngrx/store';
import { AppState, FeatureFlags } from '../lemans-app.model';

export const selectFeatureFlagsFeature = (state: AppState): FeatureFlags => {
  return state.featureFlags;
};

export const selectFlags = createSelector(selectFeatureFlagsFeature, (state: FeatureFlags) => {
  return state.flags;
});
