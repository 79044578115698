import { Role } from '@app/+state/lemans-app.model';
import { displayMessageAction } from '@app/v2/shared/utils';
import { ApiError } from '@core/api-error';
import { Action } from '@ngrx/store';
import { switchTenant } from '@ra-state';
import { showErrorDialog, unknownErrorHandler } from '@servicesV2/apierror-handlers.service';
import { ApiEndPoints } from '@servicesV2/data.service';
import { setInviteError } from './store';

export enum InvitationAcceptOutcome {
  Ok = 'Ok',
  Expired = 'Expired',
  Superseded = 'Superseded',
  NotFound = 'NotFound',
  Canceled = 'Canceled',
  AlreadyAcceptedByAnotherUser = 'AlreadyAcceptedByAnotherUser',
  TenantIsArchived = 'TenantIsArchived',
}

export enum InvitationSendError {
  InvitationAlreadyExists = 'InvitationAlreadyExists',
  UserAlreadyHasAccessToSpecifiedResource = 'UserAlreadyHasAccessToSpecifiedResource',
}

export type InvitationAcceptOutcomeValue = keyof typeof InvitationAcceptOutcome;

export type InvitationSendErrorValue = keyof typeof InvitationSendError;

export interface AcceptInvitationError {
  outcome: InvitationAcceptOutcomeValue;
  previouslyAccepted: boolean;
  resourceName: string;
  serviceKind: string;
  tenantId: string;
}

export interface SendInvitationError {
  errorCode: InvitationSendErrorValue;
  userId: string;
  userName: string;
}

export interface CreateInvitation {
  resourceId: string;
  role: Role;
  resourceType: string;
  toEmail: string;
  tenantId: string;
}

function checkErrorDomain(apiError: ApiError, domain: string): void {
  if (!apiError.Url.includes(domain)) {
    throw new Error('Url mismatch: API url does not match invitations endpoint');
  }
}
export function handleSendInvitationErrors(apiError: ApiError, body: any): Action[] {
  checkErrorDomain(apiError, ApiEndPoints.Invitations);
  const errorData: SendInvitationError = apiError.ErrorData as any;
  const createInvPayload = body as CreateInvitation;

  if (errorData === null) {
    return [unknownErrorHandler()];
  }

  switch (errorData.errorCode) {
    case InvitationSendError.InvitationAlreadyExists:
      return [setInviteError({ payload: { email: createInvPayload.toEmail, error: apiError } })];

    case InvitationSendError.UserAlreadyHasAccessToSpecifiedResource:
      return [setInviteError({ payload: { email: createInvPayload.toEmail, error: apiError } })];

    default:
      return [unknownErrorHandler()];
  }
}

export function handleAcceptInvitationErrors(apiError: ApiError, _body: any): Action[] {
  checkErrorDomain(apiError, ApiEndPoints.Invitations);
  const errorData: AcceptInvitationError = apiError.ErrorData as any;
  switch (errorData.outcome) {
    case InvitationAcceptOutcome.Expired:
      return [
        showErrorDialog(
          `Your invite to ${errorData.resourceName} expired. Ask your administrator to resend the invite.`,
        ),
      ];

    case InvitationAcceptOutcome.Canceled:
      return [showErrorDialog('This invite is no longer valid. Reach out to your administrator for help.')];

    case InvitationAcceptOutcome.AlreadyAcceptedByAnotherUser:
      return [
        showErrorDialog(
          'This invite has already been used. If you do not have access, reach out to your administrator for a new invite.',
        ),
      ];

    case InvitationAcceptOutcome.Superseded:
      return [
        displayMessageAction(
          `You already have access to ${errorData.resourceName}. For updates to your role contact your administrator`,
        ),
        switchTenant({ value: String(errorData.tenantId) }),
      ];

    case InvitationAcceptOutcome.TenantIsArchived:
      return [
        showErrorDialog(
          'The organization you were invited to is not available. Reach out to your administrator for help.',
        ),
      ];

    default:
      return [unknownErrorHandler()];
  }
}
