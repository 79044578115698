<section class="service-cell">
  <span
    *ngIf="params.data.isTrialEntitlement; else dataServiceHeader"
    raUiBadge="TRIAL"
    [badgeConfig]="{
      position: BadgePosition.After,
      color: BADGE_STATUS_COLORS.Information,
      size: BadgeSize.Small,
    }"
  >
    <div class="body-2-bold ellipsis" *ngIf="params">
      <span
        *ngIf="checkIsNew()"
        class="inline-display"
        raUiBadge="."
        [badgeConfig]="{
          position: BadgePosition.Before,
          color: BADGE_STATUS_COLORS.Information,
          size: BadgeSize.Indicator,
        }"
      ></span>
      {{ params.data?.detailServiceDataHeaders?.header }}
    </div>
  </span>
  <ng-template #dataServiceHeader>
    <div class="body-2-bold ellipsis" *ngIf="params">
      <span
        *ngIf="checkIsNew()"
        class="inline-display"
        raUiBadge="."
        [badgeConfig]="{
          position: BadgePosition.Before,
          color: BADGE_STATUS_COLORS.Information,
          size: BadgeSize.Indicator,
        }"
      ></span>
      {{ params.data?.detailServiceDataHeaders?.header }}
    </div>
  </ng-template>

  <div class="body-2 ellipsis" [ngStyle]="{ 'padding-bottom': toggle ? '10px' : '' }">
    <div *ngIf="toggle && params; else toggleElements">
      <div>Product Family: {{ params.data.productFamily }}</div>
      <div>Catalog ID: {{ params.data.catalogNumber }}</div>
      <div>Entitlement Type: {{ params.data.entitlementType }}</div>
      <div>
        <span *ngIf="params?.userEntitlement; else allocatedBy">Assigned To: {{ params.data.primaryEmail }}</span>
        <ng-template #allocatedBy>Allocated By: {{ params.data.ownerEmail }}</ng-template>
      </div>
      <span class="toggle" (click)="toggleDetails()"
        >Entitlement Details <i class="ra-icon-ide-sm-chevron-up align-middle"></i>
      </span>
    </div>
    <ng-template #toggleElements>
      <span class="toggle" (click)="toggleDetails()"
        >Entitlement Details <i class="ra-icon-ide-sm-chevron-down align-middle"></i>
      </span>
    </ng-template>
  </div>
</section>
