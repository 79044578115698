import { Injectable, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ControlPageFacade, UserDataFacade, UserPreferences, Version } from '@ra-state';
import { Subscription, tap } from 'rxjs';
import { LoggerService } from './logger.service';

interface TrackingProperties {
  userName: string;
  userId: string;
  email: string;
  tenantId: string;
  tenantName: string;
  CSAPIversion: string;
  portalVersion: string;
  notificationsAPIversion: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppInsightsMonitoringService implements OnDestroy {
  appInsights: ApplicationInsights;
  userDataFacade: UserDataFacade;
  controlPageFacade: ControlPageFacade;
  userData: UserPreferences = {} as UserPreferences;
  userData$: Subscription = new Subscription();
  versionInfos$: Subscription = new Subscription();
  versionInfo: Version = {} as Version;
  APPINSIGHTS_CONNECTION = window['APPINSIGHTS_CONNECTION'] || '';
  isConnectionStringAvailable = false;

  constructor(
    public router: Router,
    public injector: Injector,
    private logger: LoggerService,
  ) {}

  startAppInsightMonitoring(): void {
    if (this.APPINSIGHTS_CONNECTION && this.APPINSIGHTS_CONNECTION !== 'undefined') {
      this.isConnectionStringAvailable = true;
      const angularPlugin = new AngularPlugin();
      this.appInsights = new ApplicationInsights({
        config: {
          connectionString: this.APPINSIGHTS_CONNECTION,
          extensions: [angularPlugin],
          extensionConfig: {
            [angularPlugin.identifier]: { router: this.router },
          },
        },
      });
      this.appInsights.loadAppInsights();
      // Used this.injector.get(UserDataFacade) and this.injector.get(ControlPageFacade) to avoid circular dependency issue for ErrorHandler.
      // class as AppInsightErrorHandlerService is extending ErrorHandler and ngrx StoreDevtools as well
      // following is repolink for NG RX - StoreDevtools for reference
      // https://github.com/ngrx/platform/blob/a23a0a1a55cc5e14e7109d7bd92d7c3a108bb0b1/modules/store-devtools/src/devtools.ts#L58C4-L58C32
      this.userDataFacade = this.injector.get(UserDataFacade);
      this.controlPageFacade = this.injector.get(ControlPageFacade);
      this.getUserTenantData();
    } else {
      this.logger.warn('AppInsights connection string is not available');
    }
  }

  private getUserTenantData(): void {
    this.versionInfos$ = this.controlPageFacade.versionInfos$
      .pipe(
        tap((versionInfo) => {
          if (versionInfo) {
            this.versionInfo = versionInfo;
          }
        }),
      )
      .subscribe();

    this.userData$ = this.userDataFacade.userData$
      .pipe(
        tap((userData) => {
          if (userData) {
            this.userData = userData;
          }
        }),
      )
      .subscribe();
  }

  private getPropertiesForTracking(): TrackingProperties {
    const { name, email, userId, currentTenantId, currentTenant } = this.userData;
    const { apiVersion, portalVersion, notificationsVersion } = this.versionInfo;
    return {
      userName: name || '',
      userId: userId || '',
      email: email || '',
      tenantId: currentTenantId || '',
      tenantName: currentTenant?.name || '',
      CSAPIversion: apiVersion || '',
      portalVersion: portalVersion || '',
      notificationsAPIversion: notificationsVersion || '',
    };
  }

  logException(exception: Error): void {
    if (this.isConnectionStringAvailable) {
      const properties = this.getPropertiesForTracking();
      this.appInsights.trackException({
        exception: exception,
        properties,
      });
    }
  }

  trackCommandErrorRequest(req): void {
    if (this.isConnectionStringAvailable) {
      const properties = this.getPropertiesForTracking();
      const requestData = {
        id: req.headers.get('RequestId'),
        responseCode: req.status,
        target: req.url,
        name: req.name ? req.name : 'HttpResponse',
        type: 'Ajax',
        success: req.ok,
        properties: { ...properties, requestId: req.headers.get('RequestId') },
      };
      this.appInsights.trackDependencyData(requestData);
    }
  }

  ngOnDestroy(): void {
    this.versionInfos$.unsubscribe();
    this.userData$.unsubscribe();
  }
}
