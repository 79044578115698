<div class="ag-invitation-action-button-container">
  <i
    (click)="iconResendAction('resend')"
    class="resend-icon"
    aria-hidden="false"
    aria-label="Resend button"
    data-mat-icon-type="font"
    [ngClass]="{ 'disabled-btn': !buttonsConfig.btn1.enable }"
  ></i>
  <i
    (click)="iconDeleteAction('delete')"
    class="ra-icon-ide-md-delete remove-icon"
    aria-hidden="false"
    aria-label="Delete row"
    data-mat-icon-type="font"
    [raUiTooltip]="params?.tooltip"
    [tooltipConfig]="params?.tooltipConfig"
    [ngClass]="{ 'disabled-btn': !buttonsConfig.btn2.enable }"
  ></i>
</div>
