<div
  *ngIf="Status === 'Accepted' || Status === 'AcceptedAlert'; else otherstatus"
  [ngClass]="{ 'by-other': IsAcceptedByOther }"
>
  <span
    >Accepted by
    <span class="link-text" (click)="goToEditUserAccess(params.data.acceptedByUser)">{{
      params?.data?.acceptedByUserName
    }}</span>
  </span>
  <div
    class="status-icon other-email"
    *ngIf="IsAcceptedByOther"
    [raUiTooltip]="'Does not match invited email: ' + params?.data?.toEmail"
    [tooltipConfig]="tooltipConfig"
  >
    <i class="ra-icon-ide-md-color-warning"></i>
    <span>{{ params?.data?.acceptedByEmail }}</span>
  </div>
</div>
<ng-template #otherstatus>
  <span *ngIf="Status === 'Active'"> {{ getExpiryDuration(params?.data?.expiry) }} </span>
  <span *ngIf="Status !== 'Active'">{{ getInvitationStatus(params?.value) }}</span>
</ng-template>
