<div class="grid-with-toolbar">
  @if (showToolbar) {
    <div class="toolbar">
      <div class="toolbar-left-section toolbar-item">
        <ng-content select="[toolbar]"></ng-content>
      </div>
      <div class="toolbar-right-section toolbar-item">
        <ra-ui-text-button
          [disabled]="!isSortFilterAppliedImpl"
          label="Clear filters"
          icon="ra-icon-ide-sm-remove"
          [config]="{ displayIconAfterText: false }"
          (click)="onClearAllSortFilters()"
        >
        </ra-ui-text-button>
      </div>
    </div>
  }
  <ag-grid-angular
    class="ag-theme-alpine ag-grid-style"
    [getRowId]="getRowId"
    [gridOptions]="gridOptions"
    [columnDefs]="columnDefs"
    [modules]="modules"
    (gridReady)="onGridReady($event)"
    (firstDataRendered)="onFirstDataRendered($event)"
    (rowDataUpdated)="onRowDataUpdated($event)"
  >
  </ag-grid-angular>
</div>
