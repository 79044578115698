import { Component } from '@angular/core';
import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { IInnerItem } from '@ra-web-tech-ui-toolkit/components';
@Component({
  selector: 'app-organization-column-renderer',
  styleUrls: ['./organization-column-renderer.component.scss'],
  templateUrl: './organization-column-renderer.component.html',
})
export class OrganizationColumnRendererComponent implements AgRendererComponent {
  public params: any;
  organizationSelected: string;
  organizationName: string;
  organizationDesc: string;

  radioButton: IInnerItem[];

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.organizationName = this.params.data.name;
    this.organizationDesc = this.params.data.description;
    this.radioButton = [{ value: this.params.data.id, label: this.organizationName }];
  }

  // Optional - Return true if you want to manage a cell refresh yourself, otherwise return false.
  // If you return false, the grid will remove the component from the DOM and create  a new component in its place
  // with the new values.
  refresh(): boolean {
    return false;
  }

  setCurrentOrg(orgName: string): void {
    this.organizationSelected = orgName;
  }
  clicked(): void {
    this.params.clicked(this.params.data.id);
  }
}
