import { ApplicationCard } from '@ra-state';
import { LanguageItem } from '@rockwell-automation-inc/common-utils';
import { CoreConfiguration } from '@rockwell-automation-inc/service';

export enum ENV_VARS {
  CS_PORTAL_URL = 'CS_PORTAL_URL',
  CS_API_BASE_URL = 'CS_API_BASE_URL',
  CS_NOTIFICATION_API_BASE_URL = 'CS_NOTIFICATION_API_BASE_URL',
  HARNESS_KEY = 'HARNESS_KEY',
  VERSION = 'VERSION',
  AG_GRID_KEY = 'AG_GRID_KEY',
  APPINSIGHTS_CONNECTION = 'APPINSIGHTS_CONNECTION',
}
export type Environment = {
  production: boolean;
  appConfiguration: Configuration;
};
export interface Configuration extends CoreConfiguration {
  techSupportUrlPath: string;
  commercePortalUrl: string;
  applications: ApplicationCard[];
  appName(id: string): string;
  isApiCall(url: string): boolean;
  supportedLanguages: LanguageItem[];
  appInsightsConnectionString: string;
}

export enum Connection {
  Rockwell = 'Rockwell-SP',
  FactoryTalkHub = 'MyRockwellSaml',
}

export enum APP_HUBS {
  DESIGN_HUB = 'Design',
  OPERATIONS_HUB = 'Operations',
  MAINTENANCE_HUB = 'Maintenance',
}
