import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { CommonConstants } from '@core/common-constants';
import { BADGE_STATUS_COLORS, BadgePosition, BadgeSize } from '@ra-web-tech-ui-toolkit/components';

@Component({
  selector: 'app-ag-bundle-details',
  templateUrl: './ag-bundle-details.component.html',
  styleUrls: ['./ag-bundle-details.component.scss'],
})
export class AgBundleDetailsComponent {
  BADGE_STATUS_COLORS = BADGE_STATUS_COLORS;
  BadgePosition = BadgePosition;
  BadgeSize = BadgeSize;
  params: ICellRendererParams;
  highlightNewItems: boolean;
  isAllocated: boolean;
  toggle: boolean = false;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.highlightNewItems = this.params['highlightNewItems'] || false;
    this.isAllocated = this.params['isAllocated'] || false;
  }

  refresh(): boolean {
    return false;
  }

  checkIsNew(): boolean {
    if (!this.highlightNewItems) {
      return false;
    }
    const currentDate = new Date();
    const data = this.params.data;
    const redeemedOn = new Date(data.redeemedOn);
    const daysSinceRedeemed = (currentDate.getTime() - redeemedOn.getTime()) / (3600 * 24 * 1000);
    return daysSinceRedeemed <= CommonConstants.entitlementNewPeriodInDays;
  }

  toggleDetails(): void {
    this.toggle = !this.toggle;
  }
}
