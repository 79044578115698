/* eslint-disable @ngrx/prefer-concat-latest-from */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Configuration } from '@app/models/config.model';
import { getEnvironment } from '@app/v2/shared/utils';
import { CommonConstants } from '@core/common-constants';
import { environment } from '@environments/environment';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action } from '@ngrx/store';
import {
  closeTarget,
  ControlPageFacade,
  FeatureFlagsFacade,
  getUserPreferences,
  IEulaConfig,
  navigateToError,
  openWebTechDialog,
} from '@ra-state';
import { ActionButtonStyles, IDialogConfig, NotificationType } from '@ra-web-tech-ui-toolkit/components';
import { ConfigService } from '@rockwell-automation-inc/service';
import { CommandRequestBuilderService } from '@servicesV2/command-request-builder.service';
import { TenantEvent, UserEvent } from '@servicesV2/command-request.service';
import { ApiEndPoints, DataService } from '@servicesV2/data.service';
import { HarnessService } from '@servicesV2/harness.service';
import { LoggerService } from '@servicesV2/logger.service';
import * as _ from 'lodash';
import { combineLatest, from, fromEvent, Observable, of, throwError, timer } from 'rxjs';
import { catchError, delay, filter, map, mergeMap, switchMap, tap, throttleTime } from 'rxjs/operators';
import { ApplicationCard, FlagValue, ICatalogInfo, ICommandResponse, LoginErrorCode } from '../lemans-app.model';
import { RouterFacade } from '../router/router.facade';
import { setUserId } from '../user-data/user-data.action';
import {
  acceptEULA,
  acceptEULASuccess,
  baseURLSuccess,
  checkUserSession,
  extendSession,
  FinishAppInitializer,
  getApplications,
  getEnabledApplications,
  getEulaConfig,
  getEulaContent,
  logoutSession,
  navigateTo,
  recordUserActivity,
  resetSessionTimedOut,
  setErrorContext,
  setEulaConfigSuccess,
  setEulaContentSuccess,
  setExtendSession,
  setLogoutSession,
  setProductCatalogSuccess,
  setSessionTimedOut,
  setUserActive,
  setUserNotFound,
  setVersions,
  startAppInitializer,
  userLoginComplete,
  userLoginFailed,
} from './control-page.actions';

@Injectable({
  providedIn: 'root',
})
export class ControlPageEffects implements OnInitEffects {
  getTimer: () => Observable<number>;

  getMissingProfileInfoConfig(): IDialogConfig {
    return {
      title: 'Unable to sign you in',
      message:
        'It looks like your profile is missing some information needed to sign you in. Please create a support ticket and we will help resolve the issue.',
      buttons: [
        { label: 'Create ticket', buttonStyle: ActionButtonStyles.Main },
        { label: 'Close', buttonStyle: ActionButtonStyles.Outlined },
      ],
    };
  }

  getPreApprovalConfig(email, env): IDialogConfig {
    return {
      title: 'Your email does not have access',
      message: `Your email ${email} is not approved to access the Hub in the ${env} environment. Please reach out to your engineering lead to get the email added to the approval list.`,
      buttons: [{ label: 'Close', buttonStyle: ActionButtonStyles.Outlined }],
    };
  }

  getDefaultErrorConfig(): IDialogConfig {
    return {
      title: 'Unable to sign you in',
      message:
        'We ran into an unexpected issue while signing you in. Please create a support ticket and we will help resolve the issue. ',
      buttons: [
        { label: 'Create ticket', buttonStyle: ActionButtonStyles.Main },
        { label: 'Close', buttonStyle: ActionButtonStyles.Outlined },
      ],
    };
  }

  ciamSupportClick = (): void => {
    window.open(window['CIAM_SUPPORT_LINK'], '_blank');
  };

  routerSnapshot$ = this.routerFacade.queryParams$;

  initializeApp$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(startAppInitializer),
      tap(() => {
        this.harnessService.initializeTarget({
          identifier: 'common-service-portal',
          attributes: {
            Type: 'Application',
          },
        });
      }),
      switchMap(() => {
        this.configService.setConfig(environment.appConfiguration);
        this.dataService.initializeEndpoints();
        const apps = environment.appConfiguration.applications;
        const observables: Observable<ApplicationCard[]>[] = [];
        const flaggedApps = apps.filter((app) => app.featureFlagName !== undefined);
        flaggedApps.forEach((app) => {
          if (app.featureFlagName !== undefined) {
            observables.push(
              this.featureFlagsFacade.getFlagValue$(app.featureFlagName).pipe(
                map((value: FlagValue) => {
                  this.logger.debug('App dependent on flag', app.appId, app.featureFlagName, value);
                  return value ? [] : [app];
                }),
              ),
            );
          }
        });
        if (observables.length === 0) {
          return of([]);
        } else {
          return combineLatest(observables);
        }
      }),
      switchMap((appCards) => {
        const apps = environment.appConfiguration.applications;
        const excludedCards = _.flatten(appCards);
        const filteredCards = _.differenceWith(apps, excludedCards, (carda, cardb) => carda.appId === cardb.appId);
        const enabledApps = _.cloneDeep(filteredCards);
        return [
          getApplications({ payload: apps }),
          getEnabledApplications({ payload: enabledApps }),
          FinishAppInitializer(),
          baseURLSuccess({ value: true }),
        ];
      }),
    );
  });

  ngrxOnInitEffects(): Action {
    this.logger.log('Initializing effects');
    this.generateTimer();
    return startAppInitializer();
  }

  loginCompleted$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(userLoginComplete),
      switchMap(({ payload: userProfile }) => {
        const actions: Action[] = [];
        actions.push(getUserPreferences({ isInitialState: true, isNewUser: false }));
        actions.push(setUserId({ payload: userProfile.userId.replace(/-/g, '') }));
        actions.push(recordUserActivity());
        actions.push(checkUserSession());
        actions.push(closeTarget({ harnessIdentifier: 'common-service-portal' }));
        return actions;
      }),
    );
  });

  loginFailed$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(userLoginFailed),
      map(({ payload: loginError }) => {
        switch (loginError.errorCode) {
          case LoginErrorCode.MissingIdentity:
          case LoginErrorCode.MissingConnectionClaim:
          case LoginErrorCode.MissingUniqueIdentifierClaim:
          case LoginErrorCode.RootClaimMissing:
          case LoginErrorCode.InvalidConnectionClaim:
          case LoginErrorCode.InvalidMergeCandidates:
            return openWebTechDialog({
              config: this.getMissingProfileInfoConfig(),
              notificationType: NotificationType.Error,
              mainBtnHandler: this.ciamSupportClick,
              closeHandler: logoutSession(),
            });
          case LoginErrorCode.PreApprovalCheckFailed:
            const env = getEnvironment(environment.appConfiguration.csPortalUrl);
            return openWebTechDialog({
              config: this.getPreApprovalConfig(loginError.email, env),
              notificationType: NotificationType.Error,
              closeHandler: logoutSession(),
            });
          case LoginErrorCode.Unknown:
          default:
            return openWebTechDialog({
              config: this.getDefaultErrorConfig(),
              notificationType: NotificationType.Error,
              mainBtnHandler: this.ciamSupportClick,
              closeHandler: logoutSession(),
            });
        }
      }),
    );
  });

  userNotFound$ = createEffect(
    (): Observable<any> => {
      return this.actions$.pipe(
        ofType(setUserNotFound),
        concatLatestFrom(() => this.routerSnapshot$),
        tap(([payload, routeSnapshot]) => {
          if (payload.value) {
            this.router.navigate(['eula'], { queryParams: { postEula: routeSnapshot.url || '/dashboard' } });
          }
        }),
      );
    },
    { dispatch: false },
  );

  acceptEULA$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(acceptEULA),
      concatLatestFrom(() => [
        this.controlPageFacade.isUserNotFound$,
        this.controlPageFacade.eulaConfig$,
        this.routerSnapshot$,
      ]),
      switchMap(([payload, isUserNotFound, eulaConfig, routerSnapshot]) => {
        let request$: Observable<ICommandResponse<any>>;
        if (isUserNotFound) {
          const commadRequest = this.commandRequestBuilder
            .new(ApiEndPoints.Users, 'POST', TenantEvent.ServiceAddedV2)
            .withBody({ acceptedEulaVersion: eulaConfig?.eulaVersion });

          request$ = commadRequest.invoke$().pipe(
            catchError((error: unknown) => {
              this.router.navigate(['/error/user']);
              return throwError(() => error);
            }),
          );
        } else {
          const commadRequest = this.commandRequestBuilder
            .new(ApiEndPoints.Eula + eulaConfig?.eulaVersion + '/accept', 'POST', UserEvent.UserEulaAccepted)
            .withWaitOn200Ok();
          request$ = commadRequest.invoke$();
        }
        return combineLatest([request$, of(isUserNotFound), of(payload.value), of(routerSnapshot)]);
      }),
      switchMap(([resp, _isNewUser, returnTo, routerSnapshot]) => {
        this.logger.log('RESP:    ', resp);
        if (returnTo) {
          window.location.replace(returnTo);
        }
        const routerState: any = routerSnapshot;
        const path = routerState.queryParams.postEula as string;

        this.router.navigateByUrl(path);

        const actions: Action[] = [];
        actions.push(acceptEULASuccess());
        return actions;
      }),
    );
  });

  navigateTo$ = createEffect(
    (): Observable<any> => {
      return this.actions$.pipe(
        ofType(navigateTo),
        tap((payload) => {
          return [this.router.navigate([payload.path])];
        }),
      );
    },
    { dispatch: false },
  );

  navigateToError$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(navigateToError),
      map((payload) => {
        this.router.navigate(['error']);
        return setErrorContext({ payload: payload.payload });
      }),
    );
  });

  getEulaConfig$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getEulaConfig),
      switchMap(() => {
        return this.dataService.getEulaConfig$();
      }),
      switchMap((eulaConfig: IEulaConfig) => {
        return [setEulaConfigSuccess({ payload: eulaConfig }), getEulaContent({ url: eulaConfig.eulaContentUrl })];
      }),
    );
  });

  getProductCatalog$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(userLoginComplete),
      switchMap(() => {
        return this.dataService.getCatalogInfo$();
      }),
      switchMap((catalog: ICatalogInfo[]) => {
        return [setProductCatalogSuccess({ payload: catalog })];
      }),
    );
  });

  getEulaContent$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getEulaContent),
      switchMap((payload) => {
        return this.dataService.getContent$(payload.url);
      }),
      switchMap((eulaContent) => {
        return [setEulaContentSuccess({ value: eulaContent })];
      }),
    );
  });

  getVersions$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(userLoginComplete),
      switchMap(() => {
        return this.dataService.getVersions$();
      }),
      switchMap((apiVersion) => {
        return [setVersions({ value: apiVersion })];
      }),
    );
  });

  logoutSession$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(logoutSession),
      switchMap(() => [setLogoutSession({ value: true })]),
    );
  });

  extendSession$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(extendSession),
      switchMap(() => [resetSessionTimedOut(), setExtendSession({ value: true })]),
    );
  });

  recordUserActivity$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(recordUserActivity),
      delay(60000), // 1 Min Delay
      switchMap(() => from(['keydown', 'click', 'wheel', 'mousemove'])),
      mergeMap((event) => fromEvent(document, event)),
      throttleTime(600000), //Check after 10 minutes
      concatLatestFrom(() => [this.controlPageFacade.isUserActive$]),
      filter(([, isUserActive]) => !isUserActive),
      switchMap(() => [setUserActive({ value: true })]),
    );
  });

  defaultTimer = (): Observable<number> => {
    return timer(CommonConstants.idealTimeout, CommonConstants.idealTimeout);
  };

  generateTimer(getTimer = this.defaultTimer): void {
    this.getTimer = getTimer;
  }

  checkUserSession$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(checkUserSession),
      switchMap(() => this.getTimer()),
      concatLatestFrom(() => [this.controlPageFacade.sessionTimedOut$, this.controlPageFacade.isUserActive$]),
      filter(([, sessionTimedOut]) => !sessionTimedOut),
      switchMap(([, , isUserActive]) => {
        return isUserActive
          ? [setUserActive({ value: false }), extendSession()]
          : [setSessionTimedOut({ value: true })];
      }),
    );
  });

  resetSessionTimedOut$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(resetSessionTimedOut),
      switchMap(() => [setSessionTimedOut({ value: false })]),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly commandRequestBuilder: CommandRequestBuilderService,
    private readonly router: Router,
    private readonly configService: ConfigService<Configuration>,
    private readonly dataService: DataService,
    private readonly logger: LoggerService,
    private readonly controlPageFacade: ControlPageFacade,
    private readonly harnessService: HarnessService,
    private readonly routerFacade: RouterFacade,
    private readonly featureFlagsFacade: FeatureFlagsFacade,
  ) {
    this.logger.log('control page effects loads');
  }
}
