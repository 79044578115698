import { ErrorHandler, Injectable } from '@angular/core';
import { AppInsightsMonitoringService } from './app-insights-monitoring.service';

@Injectable({
  providedIn: 'root',
})
export class AppInsightErrorHandlerService implements ErrorHandler {
  constructor(private appInsightsMonitoringService: AppInsightsMonitoringService) {}
  handleError(error: any): void {
    this.appInsightsMonitoringService.logException(error);
  }
}
