<div class="edit-access-container">
  <form #myForm="ngForm" class="dropdown-container" [formGroup]="addAccess" (ngSubmit)="onSubmit()">
    <app-dropdownform
      [label]="'Resource Type'"
      [data]="selectorData"
      [controlName]="'resourceType'"
      [multiple]="false"
      [dependentControl]="true"
      [depentFormControlValues]="resourceDataTrigger"
    ></app-dropdownform>
    <app-dropdownform
      [label]="'Resource'"
      [data]="selectorData"
      [controlName]="'resourceName'"
      [multiple]="true"
    ></app-dropdownform>
    <app-dropdownform
      [label]="'Role'"
      [data]="selectorData"
      [controlName]="'role'"
      [multiple]="false"
    ></app-dropdownform>
    <div class="add-access-mgmt-btn">
      <ra-ui-main-button label="Add" type="submit" (click)="onSubmit()" [disabled]="!addAccess.valid">
      </ra-ui-main-button>
    </div>
  </form>
  <app-ag-toolbar-grid
    [columnDefs]="columnDefs"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
  ></app-ag-toolbar-grid>
  <div class="btn-container">
    <ra-ui-main-button label="Save" (click)="btnAction(1)" [disabled]="disabledActionButton"> </ra-ui-main-button>
    <ra-ui-outlined-button label="Cancel" (click)="btnAction(2)"></ra-ui-outlined-button>
  </div>
</div>
