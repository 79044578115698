import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { EntitlementStatus, ROLES_WITH_MANAGE_ACCESS, UserDataFacade, UserPreferences } from '@ra-state';
import { ITooltipConfig, TooltipPosition } from '@ra-web-tech-ui-toolkit/components';
import { tap } from 'rxjs';

@Component({
  selector: 'app-ag-deallocate-btn',
  templateUrl: './ag-deallocate-btn.component.html',
  styleUrls: ['./ag-deallocate-btn.component.scss'],
})
export class AgDeallocateBtnComponent implements ICellRendererAngularComp {
  readonly tooltipConfig: ITooltipConfig = { position: TooltipPosition.Top, maxWidth: 400 };
  ENTITLEMENT_STATUS = EntitlementStatus;
  private params: any;

  userData: UserPreferences;
  userData$ = this.userDataFacade.userData$.pipe(
    tap((userData) => {
      if (userData) {
        this.userData = userData;
      }
    }),
  );

  constructor(private userDataFacade: UserDataFacade) {}
  refresh(_params: ICellRendererParams): boolean {
    return true;
  }
  agInit(params: any): void {
    this.params = params;
  }

  btnClickedHandler(_event?: any): void {
    this.params.clicked(this.params.data);
  }

  get Status(): EntitlementStatus {
    return this.params?.data.status;
  }

  get Displayed(): boolean {
    const isUtilityTokenEntitlement = this.params.data.attributes.combineType.toLowerCase() === 'utilitytoken';
    return Boolean(isUtilityTokenEntitlement);
  }

  get showDeallocateBtn(): boolean {
    if (this.userData?.currentTenantContext?.role) {
      return ROLES_WITH_MANAGE_ACCESS.includes(this.userData.currentTenantContext.role);
    }
    return false;
  }
}
