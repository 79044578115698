import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AllocateTrial, AppState, RedeemTrial, ReservationInfo } from '../lemans-app.model';
import { allocateTrial, getTrialBundles, getTrialReservationInfo, redeemTrial } from './trials.actions';
import { selectRefreshTrialsGrid, selectTrialBundles, selectTrialReservation } from './trials.selectors';

@Injectable({
  providedIn: 'root',
})
export class TrialsFacade {
  trialReservation$ = this.store$.select(selectTrialReservation);
  trialBundles$ = this.store$.select(selectTrialBundles);
  refreshTrialsGrid$ = this.store$.select(selectRefreshTrialsGrid);

  constructor(private store$: Store<AppState>) {}
  getTrialBundles(): void {
    this.store$.dispatch(getTrialBundles());
  }
  getTrialReservationInfo(payload: ReservationInfo): void {
    this.store$.dispatch(getTrialReservationInfo({ payload: payload }));
  }
  redeemTrialReservation(payload: RedeemTrial): void {
    this.store$.dispatch(redeemTrial({ payload: payload }));
  }
  allocateTrial(payload: AllocateTrial): void {
    this.store$.dispatch(allocateTrial({ payload: payload }));
  }
}
