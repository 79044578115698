import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DomainEvent } from '@app/models/domain-event';
import { CommonConstants } from '@core/common-constants';
import { ApiEndPoints, DataService } from '@core/services/data.service';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { SerializedRouterStateSnapshot } from '@ngrx/router-store';
import { Action } from '@ngrx/store';
import {
  AGGridEntitlement,
  APP_TENANT_MGMT_ROUTES,
  displayMessage,
  getTenantLogo,
  setDomainMessageReceived,
  setTenantLogo,
} from '@ra-state';
import { CommonService } from '@rockwell-automation-inc/service';
import { ErrorHandlers } from '@servicesV2/apierror-handlers.service';
import { CommandRequestBuilderService } from '@servicesV2/command-request-builder.service';
import { CommandRequest, EntitlementEvent, TenantEvent, UserEvent } from '@servicesV2/command-request.service';
import { LoggerService } from '@servicesV2/logger.service';
import * as _ from 'lodash';
import { Observable, combineLatest, forkJoin, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { openDialogWrapper$ } from '../../v2/shared/utils';
import {
  ControlPageFacade,
  acceptEULASuccess,
  navigateTo,
  setAppIntialRoute,
  setEulaStatus,
  setUserNotFound,
  userLoginComplete,
} from '../control-page';
import { FeatureFlagsFacade, intializeTarget } from '../feature-flags';
import {
  AppFlow,
  CreateTenantResponse,
  EntitylementTypes,
  ITenantDetail,
  PendingService,
  PendingServiceV2,
  ROLES_WITH_CREDIT_LEDGER_ACCESS,
  Resource,
  Role,
  TenantStatus,
  TenantUtilityTokens,
  UploadLogoPreOperation,
  anyObjectTo,
  toUserId,
} from '../lemans-app.model';
import { openWelcomeDialog } from '../modal/modal.action';
import { RouterFacade } from '../router/router.facade';
import { UserDataFacade } from './user-data-facade';
import {
  addAccess,
  addSingleAccess,
  addSingleAccessSuccess,
  applyCustomProvisioningEntitlement,
  applyEntitlementCancelled,
  applyRegularEntitlement,
  archiveTenant,
  clearOrgEntitlementsRefresh,
  clearUserEntitlementsGridRefresh,
  clearUserRolesGridRefresh,
  createSelectedFakeEntitlements,
  createTenant,
  createTenantSuccess,
  customProvisioningCompleted,
  deallocateEntitlement,
  emptyAction,
  generateInviteCode,
  generateInviteCodeSuccess,
  getAllTenantResourceRoles,
  getServicesVisibility,
  getSuggestedTenantByCode,
  getTenantDetails,
  getTenantEffectiveRolesAndServices,
  getTenantServices,
  getTenantUsers,
  getTenantUtilityTokens,
  getUserPreferences,
  getUserPreferencesFailed,
  getUserPreferencesSuccess,
  getUserResources,
  getUserRolesForTenant,
  initializeCustomProvisioning,
  leaveCustomProvisioning,
  noLastAccessedTenant,
  refreshOrgEntitlements,
  refreshUserEntitlementsGrid,
  refreshUserRolesGrid,
  removeAccess,
  removeMulitpleAccess,
  resetTenant,
  setAllTenantResourceRolesSuccess,
  setCurrentLanguage,
  setFavoriteApps,
  setFavoriteAppsSuccess,
  setNoSuggestedTenantByCodeFound,
  setPreferences,
  setPreferencesSuccess,
  setServicesVisibilitySuccess,
  setSuggestedTenantByCodeSuccess,
  setTenantDetails,
  setTenantEffectiveRoles,
  setTenantId,
  setTenantServices,
  setTenantStatus,
  setTenantUsers,
  setTenantUtilityTokens,
  setUserResourcesSuccess,
  setUserRolesForTenantSuccess,
  shareFeedback,
  shareFeedbackSuccess,
  showTenantArchiveBanner,
  switchTenant,
  unarchiveTenant,
  updatePreferredLanguage,
  updateServicesVisibility,
  updateTenant,
  updateTenantNameSuccess,
  updateTenantSuccess,
  updateUserProfile,
  uploadOrgLogo,
  uploadOrgLogoSuccess,
  waitForCustomProvisioningComplete,
} from './user-data.action';

@Injectable({
  providedIn: 'root',
})
export class UserDataEffects {
  private readonly DASHBOARD_PATH = '/dashboard';
  private readonly ENTITLEMENT_PATH = '/entitlement';
  private readonly INVITE_USERS_PATH = '/invite-users';
  private readonly EULA_PATH = '/eula';
  openDialogWrapper$ = openDialogWrapper$;
  userID$ = this.userDataFacade.userID$.pipe(filter((value) => value !== undefined));
  currentTenantId$ = this.userDataFacade.currentTenantId$.pipe(filter((value) => value !== undefined));
  pendingServices$ = this.userDataFacade.pendingServices$;
  currentCustomProvisioningData$ = this.userDataFacade.currentCustomProvisioningData$;
  currentTenant$ = this.userDataFacade.currentTenant$;
  currentTenantContext$ = this.userDataFacade.currentTenantContext$;
  maintenanceWindow$ = this.featureFlagFacade.maintenanceWindow$;
  queryParams$ = this.routerFacade.queryParams$;
  appsMap$ = this.controlPageFacade.getAppsMap$;

  private isNotNavigatorRole = (role?: Role): boolean => {
    return role !== Role.Navigator;
  };
  getAppFlow(url): AppFlow {
    if (url.includes('/redeem')) {
      return AppFlow.RedeemTrial;
    }
    if (url.includes('invitationId')) {
      return AppFlow.Invitation;
    }
    return AppFlow.Dashboard;
  }

  getUserPreferencesFailed$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getUserPreferencesFailed),
      map((err) => {
        if (err.value.status === 404) {
          return setUserNotFound({ value: true });
        } else {
          return emptyAction();
        }
      }),
    );
  });

  loadUserPreferences$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(acceptEULASuccess, userLoginComplete, getUserPreferences),
      switchMap((payload) => combineLatest([of(payload), this.userID$])),
      switchMap(([payload, userID]) => {
        // TODO: We need to fix this type inference
        return combineLatest([
          of(payload),
          this.dataService.getUser$(String(userID)).pipe(
            catchError((error: unknown) => {
              const httpError = error as HttpErrorResponse;
              this.userDataFacade.getUserPreferencesFailed(httpError);
              return throwError(() => error);
            }),
          ),
          this.maintenanceWindow$,
          of(userID),
        ]);
      }),
      concatLatestFrom(() => this.queryParams$),
      // eslint-disable-next-line sonarjs/cognitive-complexity
      switchMap(([[payload, userPreferences, maintenanceWindow, _userID], routeState]) => {
        const { eulaAcceptanceRequired, preferences, accessibleTenants } = userPreferences;
        const appFlow = this.getAppFlow(routeState.url);
        const actions: Action[] = [];

        const updateEulaStatus = (): void => {
          const eulaData = { value: !eulaAcceptanceRequired };
          if (eulaAcceptanceRequired && !new RegExp(this.EULA_PATH).exec(this.router.url)) {
            this.navigateToEula(routeState);
          } else {
            actions.push(setAppIntialRoute({ payload: { flow: appFlow, snapshot: routeState } }));
          }
          actions.push(setEulaStatus(eulaData));
        };

        const handleEulaSuccess = (): void => {
          if (payload.type === acceptEULASuccess().type && appFlow === AppFlow.Dashboard) {
            actions.push(openWelcomeDialog());
          }
        };

        const handleMaintenanceWindow = (): void => {
          if (!maintenanceWindow.isActive) {
            this.logger.log('POST updateUserProfile');
            actions.push(updateUserProfile());
          }
        };

        const handleTenantSelection = (): void => {
          let tenantId = '';
          const { lastAccessedTenantId } = preferences || {};
          const [tenant] = accessibleTenants || [];

          if (lastAccessedTenantId) {
            tenantId = lastAccessedTenantId;
            actions.push(setTenantId({ value: tenantId }));
          } else if (accessibleTenants?.length === 1) {
            tenantId = tenant.id;
            actions.push(switchTenant({ value: tenantId }));
            actions.push(noLastAccessedTenant({ value: true }));
          } else if (accessibleTenants?.length === 2) {
            tenantId = accessibleTenants.find((t) => t.name !== 'Personal Tenant')?.id ?? '';
            actions.push(switchTenant({ value: tenantId }));
          } else if (appFlow !== AppFlow.Invitation) {
            actions.push(noLastAccessedTenant({ value: true }));
          }
        };

        updateEulaStatus();
        handleEulaSuccess();
        handleMaintenanceWindow();
        handleTenantSelection();

        actions.push(getUserPreferencesSuccess({ value: userPreferences }));

        return actions;
      }),
    );
  });

  updateUserProfile$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(updateUserProfile),
      switchMap(() => {
        return this.dataService.updateUserProfile$();
      }),
      map(() => emptyAction()),
    );
  });

  getTenantEffectiveRolesAndServices$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getTenantEffectiveRolesAndServices),
      switchMap(({ payload: tenantId }) => combineLatest([this.userID$, of(tenantId)])),
      switchMap(([userId, tenantId]) => {
        return forkJoin({
          effectiveRoles$: this.dataService.getTenantEffectiveRoles$(tenantId, userId || ''),
          services$: this.dataService.getTenantServices$(String(userId), tenantId),
          tenantId$: of(tenantId),
        });
      }),
      switchMap((response) => {
        const effectiveRoles = response.effectiveRoles$;
        const services = response.services$;
        const tenantRole = effectiveRoles.find((role) => role.resourceType === 'Tenant');
        if (!tenantRole) {
          return throwError(() => new Error('tenant role not found...'));
        }
        const actions: Action[] = [];
        actions.push(setTenantEffectiveRoles({ effectiveRoles: effectiveRoles, tenantRole: tenantRole }));
        actions.push(setTenantServices({ payload: services }));
        if (tenantRole && ROLES_WITH_CREDIT_LEDGER_ACCESS.find((role) => role === tenantRole.role)) {
          actions.push(getTenantUtilityTokens({ value: response.tenantId$ }));
        }
        if (this.isNotNavigatorRole(tenantRole?.role)) {
          actions.push(getTenantDetails({ value: response.tenantId$ }));
        } else {
          actions.push(resetTenant());
        }
        return actions;
      }),
    );
  });

  getTenantResourceRoles$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getAllTenantResourceRoles),
      switchMap((payload) => {
        return this.dataService.getAllTenantResourceRoles$(payload.payload);
      }),
      switchMap((resourceRoles) => {
        return [setAllTenantResourceRolesSuccess({ payload: resourceRoles })];
      }),
    );
  });

  getTenantUsers$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getTenantUsers),
      switchMap((payload) => {
        return this.dataService.getTenantUsers$(payload.payload);
      }),
      map((response) => {
        return setTenantUsers({ selectedTenantUsers: response });
      }),
    );
  });

  getUserResourceRoles$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getUserRolesForTenant),
      switchMap((payload) => {
        return this.dataService.getAllTenantResourceRoles$(payload.tenantId, payload.userId);
      }),
      switchMap((resourceRoles) => {
        return [setUserRolesForTenantSuccess({ payload: resourceRoles })];
      }),
    );
  });

  archiveTenant$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(archiveTenant),
      switchMap((payload) => {
        const commandRequest = this.commandRequestBuilderService
          .new(`${ApiEndPoints.Tenant}archived?state=${payload.archive}`, 'PUT', (domainMessage) => {
            return domainMessage.type === TenantEvent.Archived;
          })
          .withTenantHeader(payload.tenantId)
          .withBody({})
          .withWaitOn202Accepted();
        return combineLatest([commandRequest.invoke$(), of(payload.tenantId)]);
      }),
      switchMap(([domainResponse, _tenantId]) => {
        if (domainResponse.errorActions) {
          return domainResponse.errorActions;
        }
        const actions: Action[] = [];
        actions.push(setTenantStatus({ tenantStatus: TenantStatus.Archived }));
        return actions;
      }),
    );
  });

  unarchiveTenant$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(unarchiveTenant),
      switchMap((payload) => {
        const commandRequest = this.commandRequestBuilderService
          .new(`${ApiEndPoints.Tenant}archived?state=${payload.archive}`, 'PUT', (domainMessage) => {
            return domainMessage.type === TenantEvent.Unarchived;
          })
          .withTenantHeader(payload.tenantId)
          .withBody({})
          .withWaitOn202Accepted();
        return combineLatest([commandRequest.invoke$(), of(payload)]);
      }),
      switchMap(([domainResponse, payload]) => {
        if (domainResponse.errorActions) {
          return domainResponse.errorActions;
        }
        const actions: Action[] = [];
        actions.push(
          setTenantStatus({ tenantStatus: TenantStatus.Active }),
          displayMessage({
            payload: {
              message: `Your organization ${payload.orgName} was successfully restored. It is now available for all current members to use.`,
              type: 'Success',
            },
          }),
        );
        return actions;
      }),
    );
  });

  setTenantStatus$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(setTenantStatus),
      switchMap((payload) => combineLatest([of(payload), this.userID$, this.currentTenantId$])),
      switchMap(([tenantStatus, userId, tenantId]) => {
        return combineLatest([
          this.dataService.getTenantEffectiveRoles$(tenantId ?? '', userId ?? ''),
          of(tenantStatus),
        ]);
      }),
      switchMap(([effectiveRoles, payload]) => {
        const actions: Action[] = [];
        const tenantRole = effectiveRoles.find((role) => role.resourceType === 'Tenant');
        if (tenantRole && [Role.Owner, Role.Admin].includes(tenantRole.role)) {
          const showArchiveBanner = payload.tenantStatus === TenantStatus.Archived;
          actions.push(showTenantArchiveBanner({ show: showArchiveBanner }));
        } else if (payload.tenantStatus === TenantStatus.Archived) {
          actions.push(navigateTo({ path: this.DASHBOARD_PATH }));
          actions.push(noLastAccessedTenant({ value: true }));
          this.navbarCommonService.refreshOrganizations();
        }
        return actions;
      }),
    );
  });

  getTenantDetails$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getTenantDetails, switchTenant),
      switchMap((tenantId) => {
        return this.navbarCommonService.getTenant$(tenantId.value) as Observable<ITenantDetail>;
      }),
      switchMap((tenantDetail) => {
        return [
          setTenantDetails({ payload: tenantDetail }),
          getTenantLogo({ value: tenantDetail.logoUrl }),
          setTenantStatus({ tenantStatus: tenantDetail.tenantStatus }),
        ];
      }),
    );
  });

  getTenantLogo$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getTenantLogo),
      switchMap((payload) => {
        const tenantLogoUrl = payload.value;
        return this.dataService.getContent$(tenantLogoUrl);
      }),
      switchMap((tenantlogo) => {
        return [setTenantLogo({ value: tenantlogo })];
      }),
    );
  });

  updateHarnessTargetFlag$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(setTenantDetails),
      concatLatestFrom(() => this.userDataFacade.userData$),
      switchMap(([payload, userData]) => {
        return [
          intializeTarget({
            payload: {
              identifier: toUserId(String(userData.userId)),
              attributes: {
                CurrentTenantId: payload.payload?.id,
                CurrentTenantName: payload.payload?.name,
                Type: 'User',
                Domain: CommonConstants.getEmailDomain(userData.email),
              },
            },
          }),
        ];
      }),
    );
  });

  setTenantId$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(setTenantId),
      switchMap((payload) => {
        const tenantId = payload.value;
        this.navbarCommonService.setCurrentTenantId(tenantId);
        return [getTenantEffectiveRolesAndServices({ payload: tenantId })];
      }),
    );
  });

  setPreferences$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(setPreferences),
      concatLatestFrom(() => this.userID$),
      switchMap(([payload, userId]) => {
        return this.dataService.updateUserPreferences$(String(userId), {
          preferences: [{ name: 'lastAccessedTenantId', value: String(payload.tenantId) }],
        });
      }),
      switchMap(() => {
        return [setPreferencesSuccess(), getUserPreferences({ isInitialState: false, isNewUser: false })];
      }),
    );
  });

  udpatePreferredLanguage$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(updatePreferredLanguage),
      concatLatestFrom(() => this.userID$),
      switchMap(([payload, userId]) => {
        return combineLatest([
          this.dataService.updateUserPreferences$(String(userId), {
            preferences: [{ name: 'language', value: payload.preferredLanguage }],
          }),
          of(payload),
        ]);
      }),
      switchMap(([_response, payload]) => {
        return [setCurrentLanguage({ preferredLanguage: payload.preferredLanguage })];
      }),
    );
  });

  setFavoriteApps$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(setFavoriteApps),
      concatLatestFrom(() => this.userID$),
      switchMap(([payload, userId]) => {
        return combineLatest([
          this.dataService.updateUserPreferences$(String(userId), {
            preferences: [
              {
                name: 'favoriteApps',
                value: JSON.stringify(payload.favoriteApps),
              },
            ],
          }),
          of(payload.favoriteApps),
        ]);
      }),
      switchMap(([_response, favoriteApps]) => {
        return [setFavoriteAppsSuccess({ favoriteApps })];
      }),
    );
  });

  createTenant$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(createTenant),
      switchMap((payload) => {
        const commandRequest: CommandRequest = this.commandRequestBuilderService
          .new(
            ApiEndPoints.Tenants,
            'POST',
            // eslint-disable-next-line wrap-iife
            (function (countOfEventsToWaitFor: number, eventType: string): (domainMessage) => boolean {
              let countOfEventsReceived: number = 0;
              return (domainMessage): boolean => {
                if (domainMessage.type === eventType) {
                  countOfEventsReceived += 1;
                }
                return countOfEventsToWaitFor === countOfEventsReceived;
              };
            })(payload.payload.trialServices.length, TenantEvent.ServiceAddedV2),
          )
          .withIdempotencyKey(payload.idempotencyKey)
          .withBody(payload.payload);

        return combineLatest([
          commandRequest.invoke$(),
          of(payload.blobImage),
          of(payload.servicesVisibilityRequestBody),
          of(payload.payload.name),
        ]);
      }),
      switchMap(([response, blobImage, servicesVisibilityRequestBody, organizationName]) => {
        if (response.error && response.errorActions) {
          return response.errorActions;
        }

        const newTenantId = (response.response?.body as CreateTenantResponse)?.tenantId;
        const actions: Action[] = [];
        this.navbarCommonService.createNewTenant(newTenantId, organizationName);
        actions.push(switchTenant({ value: newTenantId }));
        actions.push(
          createTenantSuccess({
            payload: {
              newTenantId: newTenantId,
              blobImage: blobImage,
              servicesVisibilityRequestBody: servicesVisibilityRequestBody,
            },
          }),
        );
        return actions;
      }),
    );
  });

  createTenantSuccess$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(createTenantSuccess),
      map((payload) => {
        const doUpdateVisibilityRequest = payload.payload.servicesVisibilityRequestBody.some(
          (service) => service.hidden === true,
        );
        if (doUpdateVisibilityRequest) {
          return updateServicesVisibility({
            tenantId: payload.payload.newTenantId,
            payload: payload.payload.servicesVisibilityRequestBody,
          });
        }
        return emptyAction;
      }),
    );
  });

  uploadTenantLogoPostCreate$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(createTenantSuccess),
      switchMap((payload) => {
        const actions: Action[] = [];
        if (payload.payload.blobImage.size > 0) {
          actions.push(
            uploadOrgLogo({
              blobImage: payload.payload.blobImage,
              preOperation: UploadLogoPreOperation.CreateTenant,
              tenantId: payload.payload.newTenantId,
            }),
          );
        }
        return actions;
      }),
    );
  });

  uploadOrgLogo$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(uploadOrgLogo),
      switchMap((payload) => {
        const request$ = this.dataService.getUploadLogoUrl$(String(payload.tenantId));
        return combineLatest([request$, of(payload)]);
      }),
      switchMap(([logoUrl, payload]) => {
        const request$ = this.dataService.uploadOrgLogo$(logoUrl.url, payload.blobImage);
        return combineLatest([request$, of(payload), of(logoUrl.url)]);
      }),
      switchMap(([_res, payload, logoUrl]) => {
        this.navbarCommonService.setTenantLogo(payload.tenantId, logoUrl);
        const actions: Action[] = [];
        actions.push(uploadOrgLogoSuccess());
        this.mapActionsForUploadOrgLogo(actions, payload.preOperation, payload.tenantId);
        return actions;
      }),
    );
  });

  updateTenant$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(updateTenant),
      switchMap((payload) => {
        const commandRequest = this.commandRequestBuilderService
          .new(`${ApiEndPoints.Tenant}`, 'PUT', (domainMessage) => {
            return (
              domainMessage.type === TenantEvent.TenantUpdated || domainMessage.type === TenantEvent.TenantNameUpdated
            );
          })
          .withTenantHeader(payload.payload.tenantId)
          .withBody(payload.payload)
          .withWaitOn200Ok();

        return combineLatest([commandRequest.invoke$(), of(payload.blobImage), of(payload.payload.name)]);
      }),
      switchMap(([response, blobImage, tenantName]) => {
        if (response.error && response.errorActions) {
          return response.errorActions;
        }

        const tenantId = response.message?.context.tenantId;
        const actions: Action[] = [];
        if (blobImage) {
          if (response.message?.type === TenantEvent.TenantNameUpdated) {
            actions.push(
              uploadOrgLogo({
                blobImage: blobImage,
                preOperation: UploadLogoPreOperation.UpdateTenantName,
                tenantId: String(tenantId),
              }),
            );
          } else {
            actions.push(
              uploadOrgLogo({
                blobImage: blobImage,
                preOperation: UploadLogoPreOperation.UpdateTenant,
                tenantId: String(tenantId),
              }),
            );
          }
        } else {
          actions.push(updateTenantSuccess());
          actions.push(
            displayMessage({
              payload: { message: 'Organization updated successfully.', type: 'Success' },
            }),
          );
          if (response.message?.type === TenantEvent.TenantNameUpdated) {
            actions.push(updateTenantNameSuccess({ value: tenantName }));
            // TODO: is this required?
            //actions.push(getUserPreferences({ isInitialState: false, isNewUser: false })); ???
            this.navbarCommonService.setCurrentTenantIdWithNameUpdate(String(tenantId).replace(/-/g, ''), tenantName);
          }
          actions.push(getTenantDetails({ value: String(tenantId) }));
        }
        return actions;
      }),
    );
  });

  generateInviteCode$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(generateInviteCode),
      switchMap((payload) => {
        const commandRequest = this.commandRequestBuilderService
          .new(`${ApiEndPoints.Tenant}invitecode`, 'POST', TenantEvent.InviteCodeGenerated)
          .withTenantHeader(payload.tenantId)
          .withWaitOn200Ok();
        return combineLatest([commandRequest.invoke$(), of(payload)]);
      }),
      switchMap(([response, payload]) => {
        if (response.error && response.errorActions) {
          return response.errorActions;
        }

        return [
          generateInviteCodeSuccess(),
          getTenantDetails({ value: payload.tenantId }),
          displayMessage({
            payload: {
              message: 'You have created a new invite link successfully!',
              type: 'Success',
            },
          }),
        ];
      }),
    );
  });

  switchTenant$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(switchTenant),
      switchMap((payload) => {
        const dispatchActions: Action[] = [];
        dispatchActions.push(setPreferences({ tenantId: payload.value }));
        dispatchActions.push(setTenantId({ value: payload.value }));
        if (APP_TENANT_MGMT_ROUTES.includes(this.router.url.toLowerCase())) {
          dispatchActions.push(navigateTo({ path: this.DASHBOARD_PATH }));
        }
        return dispatchActions;
      }),
    );
  });

  shareFeedback$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(shareFeedback),
      switchMap((payload) => {
        return this.dataService.shareFeedback$(payload.payload);
      }),
      map(() => {
        return shareFeedbackSuccess();
      }),
    );
  });

  getSuggestedTenantByCode$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getSuggestedTenantByCode),
      filter((value) => value.inviteCode !== undefined),
      switchMap((value) => {
        return this.dataService.getSuggestedTenantByCode$(String(value.inviteCode));
      }),
      map((suggestedTenants) => {
        if (!suggestedTenants.length) {
          return setNoSuggestedTenantByCodeFound({ payload: true });
        } else {
          return setSuggestedTenantByCodeSuccess({ payload: suggestedTenants[0] });
        }
      }),
    );
  });

  getUserResources$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getUserResources),
      filter((value) => value.value !== undefined),
      switchMap((value) => {
        return this.dataService.getUserResources$(String(value.value));
      }),
      switchMap((resources: Resource[]) => {
        return [setUserResourcesSuccess({ payload: resources })];
      }),
    );
  });

  createSelectedFakeEntitlements$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(createSelectedFakeEntitlements),
      switchMap((value) => {
        const payload = value.payload;
        const commandRequest = this.commandRequestBuilderService
          .new(
            '/api/users/entitlements/update',
            'POST',
            (msg) => msg.type === TenantEvent.EntitlementAddedV2 || msg.type === TenantEvent.TokenEntitlementAllocated,
          )
          .withTenantHeader(value.tenantId)
          .withBody(payload)
          .withWaitOn200Ok();

        return commandRequest.invoke$();
      }),
      switchMap((response) => {
        if (response.error && response.errorActions) {
          return response.errorActions;
        }
        return [refreshUserEntitlementsGrid(), clearUserEntitlementsGridRefresh()];
      }),
    );
  });

  applyRegularEntitlement$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(applyRegularEntitlement),
      switchMap((value) => {
        const url = `/api/users/${value.userId}/entitlements/${value.entitlementData.entitlement.id}?quantity=${value.quantity}`;
        const productFamilyName = (value.entitlementData?.entitlement as AGGridEntitlement)?.productFamily;
        const commandRequest = this.commandRequestBuilderService
          .new(
            url,
            'POST',
            (msg) => msg.type === TenantEvent.EntitlementAddedV2 || msg.type === TenantEvent.TokenEntitlementAllocated,
          )
          .withTenantHeader(value.tenantId)
          .withContext({ productFamilyName })
          .withCustomTimer('7000')
          .withWaitOn202Accepted()
          .withErrorHandler({
            [HttpStatusCode.BadRequest]: this.errorHandlers.handleEntitlementErrors,
          });

        return combineLatest([commandRequest.invoke$(), of(value)]);
      }),
      switchMap(([response, value]) => {
        if (response.error && response.errorActions) {
          return response.errorActions;
        }
        const { attributes, available, name } = value.entitlementData.entitlement;
        let messageBody = '';
        let customBtn;
        if (attributes.combineType !== 'utilityToken') {
          messageBody = `${name} allocated successfully to ${value.organizationName}.`;
          customBtn = {
            label: 'Invite Users',
            navigateTo: '/invite-users',
          };
        } else {
          messageBody = `${value.quantity === available ? 'Full' : value.quantity} ${name} allocated successfully to ${
            value.organizationName
          }.`;
        }

        return [
          refreshUserEntitlementsGrid(),
          refreshOrgEntitlements(),
          displayMessage({
            payload: {
              message: messageBody,
              type: 'Success',
              customBtn: customBtn,
            },
          }),
          getTenantEffectiveRolesAndServices({ payload: String(value.tenantId) }),
          clearUserEntitlementsGridRefresh(),
          clearOrgEntitlementsRefresh(),
        ];
      }),
    );
  });

  applyCustomProvisioningEntitlement$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(applyCustomProvisioningEntitlement),
      switchMap((value) => {
        const url = `/api/users/${value.userId}/entitlements/${value.entitlementData.entitlement.id}?quantity=${value.quantity}`;
        const commandRequest = this.commandRequestBuilderService
          .new(url, 'POST', (msg) => msg.type === TenantEvent.TrialAllocationUserActionPending)
          .withTenantHeader(value.tenantId)
          .withWaitOn202Accepted()
          .withErrorHandler({
            [HttpStatusCode.BadRequest]: this.errorHandlers.handleEntitlementErrors,
          });
        return combineLatest([commandRequest.invoke$(), of(value)]);
      }),
      switchMap(([response, value]) => {
        if (response.error && response.errorActions) {
          return response.errorActions;
        }
        const pendinServiceEvent = new DomainEvent<PendingServiceV2>(response.message);
        const serviceId = pendinServiceEvent.EventData.service.serviceId;
        return [
          initializeCustomProvisioning({
            payload: {
              app: value.entitlementData.app,
              tenantId: value.tenantId,
              entitlement: value.entitlementData.entitlement,
              serviceId: serviceId,
              userId: value.userId,
            },
          }),
        ];
      }),
    );
  });

  openIframe$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(initializeCustomProvisioning),
      switchMap((currentPSD) => {
        this.router.navigate(['/entitlement/hub'], {
          queryParams: {
            tenantId: currentPSD.payload.tenantId,
            serviceId: currentPSD.payload.serviceId,
          },
        });
        return [waitForCustomProvisioningComplete({ payload: currentPSD.payload })];
      }),
    );
  });

  waitForCustomProvisioningComplete$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(setDomainMessageReceived),
      filter((payload) => payload.payload.type === TenantEvent.ServiceAddedV2),
      mergeMap((payload) => combineLatest([of(payload), this.currentCustomProvisioningData$])),
      switchMap(([payload, currentPSD]) => {
        const actions: Action[] = [];
        const data = anyObjectTo<PendingService>(payload.payload.data);
        if (data.serviceId !== currentPSD?.serviceId) {
          actions.push(emptyAction());
          return actions;
        }
        actions.push(getTenantEffectiveRolesAndServices({ payload: String(currentPSD.tenantId) }));
        actions.push(customProvisioningCompleted());
        this.router.navigate([this.INVITE_USERS_PATH]);
        return actions;
      }),
    );
  });

  waitForCustomProvisioningCancelation$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(setDomainMessageReceived),
      filter((payload) => payload.payload.type === TenantEvent.ServiceAdditionCancelled),
      mergeMap((payload) => combineLatest([of(payload), this.currentCustomProvisioningData$])),

      //delay(1500),
      // NOTE - fixed the event type when client cancels CSP flow and we have to run compensating action
      // this delay was probably a workaround - https://github.com/Rockwell-Automation-Inc/LeMans-Home-UI/pull/1206 that
      // had the wrong event; now since we changed the event type, removing the delay.

      switchMap(([payload, currentPSD]) => {
        const actions: Action[] = [];
        //TODO: Remove - now that we have parsed json from the server
        const data: PendingServiceV2 = payload.payload.data as unknown as PendingServiceV2;

        if (data.service.serviceId !== currentPSD?.serviceId) {
          actions.push(emptyAction());
          return actions;
        }

        actions.push(getTenantEffectiveRolesAndServices({ payload: String(currentPSD.tenantId) }));
        actions.push(
          getTenantServices({
            payload: {
              tenantId: String(currentPSD.tenantId),
              userId: String(currentPSD.userId),
            },
          }),
        );
        actions.push(applyEntitlementCancelled());
        actions.push(leaveCustomProvisioning());

        this.router.navigate([this.ENTITLEMENT_PATH]);
        return actions;
      }),
    );
  });

  deallocateEntitlement$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(deallocateEntitlement),
      switchMap((payload) => {
        const entitlemenId = payload.entitlement.id;
        const url = `/api/tenant/entitlements/${entitlemenId}/deallocate`;
        const commandRequest = this.commandRequestBuilderService
          .new(url, 'POST', (msg) => msg.type === EntitlementEvent.EntitlementDeallocated)
          .withBody({
            code: entitlemenId,
            allocationTimestamp: payload.entitlement.timestamp,
            allocated: payload.entitlement.allocated,
            quantity: payload.quantity === 'Full' ? null : payload.quantity,
          })
          .withTenantHeader(payload.tenantId)
          .withWaitOn202Accepted();

        return combineLatest([commandRequest.invoke$(), of(payload)]);
      }),
      concatLatestFrom(() => this.userID$),
      switchMap(([[response, payload], userId]) => {
        if (response.error && response.errorActions) {
          return response.errorActions;
        }
        return [
          getTenantEffectiveRolesAndServices({ payload: String(payload.tenantId) }),
          refreshOrgEntitlements(),
          refreshUserEntitlementsGrid(),
          getTenantServices({
            payload: {
              tenantId: String(payload.tenantId),
              userId: String(userId),
            },
          }),
          displayMessage({
            payload: {
              message: `${payload.quantity} ${payload.entitlement['detailServiceDataHeaders'].header} removed successfully from ${payload.organizationName}.`,
              type: 'Success',
            },
          }),
          clearUserEntitlementsGridRefresh(),
          clearOrgEntitlementsRefresh(),
        ];
      }),
    );
  });

  addAccess$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(addAccess),
      mergeMap((addAccessList) => {
        const dispatchAccessList: Action[] = [];
        const list = addAccessList.payload;
        list.forEach((item) =>
          dispatchAccessList.push(
            addSingleAccess({ flow: addAccessList.flow, tenantId: addAccessList.tenantId, value: item }),
          ),
        );
        return dispatchAccessList;
      }),
    );
  });

  addSingleAccess$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(addSingleAccess),
      mergeMap((tenantAccess) => {
        const tenantAccessData = tenantAccess.value;
        const bodyRequest = {
          resourceId: tenantAccessData.resourceId,
          role: tenantAccessData.role,
        };
        const commandRequest = this.commandRequestBuilderService
          .new(ApiEndPoints.Users + tenantAccess.value.userId + '/roles', 'POST', UserEvent.UserRoleAssigned)
          .withTenantHeader(tenantAccess.tenantId)
          .withBody(bodyRequest)
          .withWaitOn200Ok();
        return combineLatest([of(tenantAccess.flow), commandRequest.invoke$()]);
      }),
      mergeMap(([flow, response]) => {
        if (response.error && response.errorActions) {
          return response.errorActions;
        }

        let message = '';
        if (flow === 'add') {
          message = CommonConstants.responseMessageIconColorMap['add-access'].message;
        } else {
          message = CommonConstants.responseMessageIconColorMap['edit-access'].message;
        }
        if (flow !== 'approve') {
          this.router.navigate(['/access-mngmt']);
        }
        return [
          addSingleAccessSuccess(),
          displayMessage({
            payload: {
              message: message,
              type: 'Success',
            },
          }),
        ];
      }),
    );
  });

  removeMulitpleAccess$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(removeMulitpleAccess),
      mergeMap((removeAccessList) => {
        const dispatchRemoveAccessList: Action[] = [];
        const list = removeAccessList.payload;
        list.forEach((item) => dispatchRemoveAccessList.push(removeAccess({ payload: item })));
        return dispatchRemoveAccessList;
      }),
    );
  });

  deleteAccess$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(removeAccess),
      mergeMap((removeData) => {
        const data = removeData.payload;
        const commandRequest = this.commandRequestBuilderService
          .new(
            ApiEndPoints.Users + data.userId + '/roles/?resid=' + data.resourceId,
            'DELETE',
            UserEvent.UserRoleRevoked,
          )
          .withTenantHeader(data.tenantId)
          .withWaitOn200Ok();

        return commandRequest.invoke$();
      }),
      mergeMap((response) => {
        if (response.error && response.errorActions) {
          return response.errorActions;
        }

        this.router.navigate(['/access-mngmt']);
        return [
          refreshUserRolesGrid(),
          displayMessage({
            payload: {
              message: 'Access has been removed successfully.',
              type: 'Info',
            },
          }),
          clearUserRolesGridRefresh(),
        ];
      }),
    );
  });
  navigateToEula(routeSnapshot: SerializedRouterStateSnapshot): void {
    this.router.navigate(['eula'], { queryParams: { postEula: routeSnapshot.url || '/dashboard' } });
  }

  mapActionsForUploadOrgLogo(actions: Action[], preOperation: UploadLogoPreOperation, tenantId: string): Action[] {
    const displayMessageAction = displayMessage({
      payload: {
        message: 'Organization updated successfully.',
        type: 'Success',
      },
    });

    switch (preOperation) {
      case UploadLogoPreOperation.CreateTenant: {
        actions.push(switchTenant({ value: tenantId }));
        break;
      }
      case UploadLogoPreOperation.UpdateTenantName: {
        actions.push(displayMessageAction);
        actions.push(getUserPreferences({ isInitialState: false, isNewUser: false }));
        break;
      }
      case UploadLogoPreOperation.UpdateTenant:
      case UploadLogoPreOperation.None:
      default: {
        actions.push(displayMessageAction);
        actions.push(getTenantDetails({ value: tenantId }));
        break;
      }
    }
    return actions;
  }

  getServicesVisibility$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getServicesVisibility),
      switchMap((tenantId) => {
        return this.dataService.getServicesVisibility$(tenantId.value);
      }),
      concatLatestFrom(() => this.controlPageFacade.getApps$),
      map(([servicesVisibility, enabledApps]) => {
        const filteredVisibility = servicesVisibility.filter((service) => {
          return Boolean(enabledApps.find((app) => app.appId === service.serviceKind));
        });
        return setServicesVisibilitySuccess({ payload: filteredVisibility });
      }),
    );
  });

  updateServicesVisibility$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(updateServicesVisibility),
      switchMap((servicesVisibilityData) => {
        const commandRequest = this.commandRequestBuilderService
          .new(`${ApiEndPoints.Tenant}services/visibility`, 'POST', TenantEvent.ServiceVisibilityUpdated)
          .withBody(servicesVisibilityData.payload)
          .withTenantHeader(servicesVisibilityData.tenantId)
          .withWaitOn202Accepted();

        return combineLatest([commandRequest.invoke$(), of(servicesVisibilityData), this.userID$]);
      }),
      switchMap(([response, servicesVisibilityData, userId]) => {
        if (response.error && response.errorActions) {
          return response.errorActions;
        }
        return [
          getServicesVisibility({ value: servicesVisibilityData.tenantId }),
          getTenantServices({
            payload: {
              tenantId: String(servicesVisibilityData.tenantId),
              userId: String(userId),
            },
          }),
          displayMessage({
            payload: {
              message: 'Applications visibility updated successfully.',
              type: 'Success',
            },
          }),
        ];
      }),
    );
  });

  getTenantServices$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getTenantServices),
      switchMap((payload) => {
        const userTenant = payload.payload;
        return this.dataService.getTenantServices$(userTenant.userId, userTenant.tenantId);
      }),
      switchMap((services) => {
        return [setTenantServices({ payload: services })];
      }),
    );
  });

  getTenantUtilityTokens$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getTenantUtilityTokens),
      switchMap((tenantId) => {
        return this.dataService.getTenantUtilityTokens$(tenantId.value);
      }),
      switchMap((tenantUtilityTokens: TenantUtilityTokens) => {
        //TODO: remove this piece of code when the backend provides the correct combineType
        tenantUtilityTokens.tokenEntitlements.forEach((entitlement) => {
          if (_.isEmpty(entitlement.attributes)) {
            entitlement.attributes = {
              combineType: EntitylementTypes.utilityToken,
            };
          }
        });
        tenantUtilityTokens.disabledTokenEntitlements.forEach((disabledEntitlement) => {
          if (_.isEmpty(disabledEntitlement.attributes)) {
            disabledEntitlement.attributes = {
              combineType: EntitylementTypes.utilityToken,
            };
          }
        });
        return [setTenantUtilityTokens({ payload: tenantUtilityTokens })];
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly userDataFacade: UserDataFacade,
    private readonly controlPageFacade: ControlPageFacade,
    private readonly router: Router,
    private readonly dataService: DataService,
    private readonly commandRequestBuilderService: CommandRequestBuilderService,
    private readonly logger: LoggerService,
    private readonly navbarCommonService: CommonService,
    private readonly featureFlagFacade: FeatureFlagsFacade,
    private readonly routerFacade: RouterFacade,
    private readonly errorHandlers: ErrorHandlers,
  ) {}
}
