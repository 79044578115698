import { Configuration } from '@app/models/config.model';
import { ApplicationCard, PreviewAppId } from '@ra-state';
import { AppId } from '@rockwell-automation-inc/common-utils';
import { createEnvConfig } from './applications';

const APPS: Partial<ApplicationCard>[] = [
  {
    appURL: 'https://vault.cloud.rockwellautomation.com/vault?tenantId=${tenantId}',
    appId: AppId.Vault,
  },
  {
    appURL: 'https://ftra-wizard.cloud.rockwellautomation.com/auto-sign-in?tenantId=${tenantId}',
    appId: AppId.SecureRemoteAccess,
  },
  {
    appURL:
      'https://lp.fiixsoftware.com/fiix-factorytalk-hub.html?utm_source=rockwell&utm_medium=factorytalk&utm_campaign=other',
    appId: PreviewAppId.Fiix,
  },
  {
    appURL: 'https://optix.cloud.rockwellautomation.com/dashboard/?tenantId=${tenantId}',
    appId: AppId.FTOptix,
  },
  {
    appURL: 'https://home.ftds.rockwellautomation.com/?tenantId=${tenantId}',
    appId: AppId.IDE,
  },
  {
    appURL: 'https://www.plex.com/',
    appId: PreviewAppId.Plex,
  },
  {
    appURL: 'https://twinstudio.cloud.rockwellautomation.com/?tenantId=${tenantId}',
    appId: AppId.TwinStudio,
  },
  {
    appURL: 'https://datamosaix-portal.cloud.rockwellautomation.com?tenantId=${tenantId}',
    appId: AppId.DataMosaix,
  },
  {
    appURL: 'https://ftem.cloud.rockwellautomation.com?tenantId=${tenantId}',
    appId: AppId.EnergyManager,
  },
  {
    appURL: 'https://bpa.cloud.rockwellautomation.com?tenantId=${tenantId}',
    appId: AppId.BatchPerformanceAnalytics,
  },
  {
    appURL: 'https://plantpaxanalytics.cloud.rockwellautomation.com?tenantId=${tenantId}',
    appId: AppId.PPaxAnalytics,
  },
  {
    appURL: 'https://ftma.cloud.rockwellautomation.com',
    appId: PreviewAppId.FactoryTalkMotionAnalyzer,
  },
  {
    appURL: 'https://visionai.elementaryml.com',
    appId: AppId.Elementary,
  },
  {
    appURL: 'https://advisor.rockwellautomation.com',
    appId: PreviewAppId.Advisor,
  },
  {
    appURL: 'https://ftmepm.cloud.rockwellautomation.com',
    appId: AppId.MEPM,
  },
];

const appConfig: Partial<Configuration> = {
  production: true,
  name: 'home-lemans-prod',
  csApiBaseUrl: '',
  csPortalUrl: 'https://home.cloud.rockwellautomation.com',
  notificationsBaseUrl: '/notifications',
  techSupportUrlPath: '/p/5794/c/5346',
  commercePortalUrl: 'https://commerce.rockwellautomation.com',
  customSignInUrl: 'https://home.cloud.rockwellautomation.com/sign-in',
  authConfig: {
    domain: 'factorytalkhub.us.auth0.com',
    clientId: 'pDcl3IpDhGCbYSHGr3YTT0BLH6aetTmh',
    audience: 'https://lemans.common',
    scope: 'email read:user',
    useRefreshToken: true,
    cacheLocation: 'memory',
    connection: 'MyRockwellSaml',
    errorPath: '/sign-in',
  },
  isForCSPortal: true,
  rockwellAuthConfig: {
    rockwellAuth0ClientId: 'AxuEJIJPPjfO9UxfHgsoGc9yXAQ9D0QF',
    rockwellAuth0Domain: 'login.rockwellautomation.com',
  },
  supportedLanguages: [
    {
      langText: 'English',
      langCode: 'en-US',
    },
  ],
};

export const environment = createEnvConfig(appConfig, APPS);
