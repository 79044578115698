import { AlertModalConfig, CatalogModalConfig, ConfirmModalConfig } from '@core/modal/modal.service';
import { Action, createAction, props } from '@ngrx/store';
import { IDialogConfig, NotificationType } from '@ra-web-tech-ui-toolkit/components';

export const openConfirmDialog = createAction('[MODAL] Confirm Dialog', props<{ payload: ConfirmModalConfig }>());
export const openAlertDialog = createAction('[MODAL] Alert Dialog', props<{ payload: AlertModalConfig }>());
export const openWelcomeDialog = createAction('[MODAL] Welcome Dialog');
export const openCatalogDialog = createAction('[MODAL] Catalog Dialog', props<{ payload: CatalogModalConfig }>());
export const openWebTechDialog = createAction(
  '[MODAL] WebTech Dialog',
  props<{
    config: IDialogConfig;
    notificationType?: NotificationType;
    mainBtnHandler?: () => void;
    closeHandler?: Action;
  }>(),
);
