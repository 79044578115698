import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@environments/environment';

import { ModuleRegistry } from '@ag-grid-community/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { ENV_VARS } from '@app/models/config.model';
import { getEnv } from '@app/v2/shared/utils';

if (environment.production) {
  enableProdMode();
}

LicenseManager.setLicenseKey(getEnv(ENV_VARS.AG_GRID_KEY));

ModuleRegistry.register(ServerSideRowModelModule);
ModuleRegistry.register(ClientSideRowModelModule);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));
