import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ResourceRoleGuard } from '@core/guards/resource-role.guard';
import { TenantRoleGuard } from '@core/guards/tenant-role.guard';
import { Role } from '@ra-state';
import { AuthGuardService } from '@rockwell-automation-inc/service';
import { ErrorLoginComponent } from './v2/error-login/error-login.component';
import { EulaComponent } from './v2/eula/eula.component';
import { Shell } from './v2/shell/shell.service';
import { LandingComponent } from './v2/auth/landing/landing.component';
import { AuthCallbackComponent } from './v2/auth/auth-callback/auth-callback.component';

const routes: Routes = [
  { component: LandingComponent, path: 'sign-in' },
  { component: AuthCallbackComponent, path: 'callback' },
  {
    data: { breadcrumb: 'Home' },
    path: '',
    pathMatch: 'prefix',
    redirectTo: 'dashboard',
  },
  {
    canActivate: [AuthGuardService],
    component: EulaComponent,
    loadChildren: (): Promise<any> => import('./v2/eula/eula.module').then((mo) => mo.EulaModule),
    path: 'eula',
  },
  {
    component: ErrorLoginComponent,
    path: 'error/login',
  },
  // { path: '**', redirectTo: 'dashboard'},
  Shell.childRoutes([
    {
      children: [
        {
          loadChildren: (): Promise<any> => import('./v2/dashboard/dashboard.module').then((mo) => mo.DashboardModule),
          path: 'dashboard',
        },
        {
          canActivate: [TenantRoleGuard],
          data: {
            breadcrumb: 'View Entitlements',
            roles: [Role.Admin, Role.Owner, Role.Contributor, Role.Navigator],
          },
          loadChildren: (): Promise<any> =>
            import('./v2/entitlement/entitlement.module').then((mo) => mo.EntitlementModule),
          path: 'entitlement',
        },
        {
          loadChildren: (): Promise<any> =>
            import('./v2/organizations/organizations.module').then((mo) => mo.OrganizationsModule),
          path: 'organization',
        },
        {
          canActivate: [AuthGuardService],
          loadChildren: (): Promise<any> => import('./v2/trials/trials.module').then((mo) => mo.TrialsModule),
          path: 'users',
        },
        {
          canActivate: [ResourceRoleGuard],
          data: { breadcrumb: 'Send invites', roles: [Role.Admin, Role.Owner] },
          loadChildren: (): Promise<any> =>
            import('./v2/invite-users/invite-users.module').then((mo) => mo.InviteUsersModule),
          path: 'invite-users',
        },
        {
          canActivate: [AuthGuardService],
          data: { breadcrumb: 'Join Organization' },
          loadChildren: (): Promise<any> =>
            import('./v2/join-request/join-request.module').then((mo) => mo.JoinRequestModule),
          path: 'request/:id',
        },
        {
          canActivate: [TenantRoleGuard],
          data: { breadcrumb: 'Manage User Access', roles: [Role.Admin, Role.Owner] },
          loadChildren: (): Promise<any> =>
            import('./v2/access-management/access-management.module').then((mo) => mo.AccessManagementModule),
          path: 'access-mngmt',
        },
        {
          canActivate: [TenantRoleGuard],
          data: { breadcrumb: 'Approve Users', roles: [Role.Admin, Role.Owner] },
          loadChildren: (): Promise<any> =>
            import('./v2/approve-users/approve-users.module').then((mo) => mo.ApproveUsersModule),
          path: 'approve-users',
        },
        {
          loadChildren: (): Promise<any> =>
            import('./v2/utility-tokens/utility-tokens.module').then((mo) => mo.UtilityTokensModule),
          path: 'universalcredits',
        },
        {
          data: { breadcrumb: 'Error' },
          loadChildren: (): Promise<any> => import('./v2/error/error.module').then((mo) => mo.ErrorModule),
          path: 'error',
        },
        {
          loadChildren: (): Promise<any> => import('./v2/about/about.module').then((mo) => mo.AboutModule),
          data: { breadcrumb: 'About' },
          path: 'about',
        },
      ],
      path: '',
    },
  ]),
  { path: '**', redirectTo: '/dashboard' },
];
@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', useHash: false })],
})
export class AppRoutingModule {}
