/* eslint-disable @ngrx/on-function-explicit-return-type */
import { Action, createReducer, on } from '@ngrx/store';
import { FeatureFlags } from '../lemans-app.model';
import { deleteFeatureFlag, setFeatureFlags, updateFeatureFlag } from './feature-flags.actions';

export const featureFlagsInitialState: FeatureFlags = {};

const featureFlagsActions = createReducer(
  featureFlagsInitialState,
  on(setFeatureFlags, (state: FeatureFlags, { flags }) => {
    const allFlags = { ...flags };
    return { ...state, flags: allFlags };
  }),
  on(updateFeatureFlag, (state: FeatureFlags, { payload: harnessUpdate }) => {
    const flags = { ...state.flags };
    flags[harnessUpdate.flag] = harnessUpdate.value;
    return { ...state, flags: flags };
  }),
  on(deleteFeatureFlag, (state: FeatureFlags, { payload: flag }) => {
    const flags = { ...state.flags };
    delete flags[flag];
    return { ...state, flags: flags };
  }),
);

export function featureFlagsReducer(state: FeatureFlags, action: Action): FeatureFlags {
  return featureFlagsActions(state, action);
}
