import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalService } from '@core/modal/modal.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  ActionButtonStyles,
  DialogComponent,
  DialogService,
  IDialogConfig,
  NotificationType,
} from '@ra-web-tech-ui-toolkit/components';
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { emptyAction } from '../user-data';
import {
  openAlertDialog,
  openCatalogDialog,
  openConfirmDialog,
  openWebTechDialog,
  openWelcomeDialog,
} from './modal.action';

@Injectable({
  providedIn: 'root',
})
export class ModalEffects {
  private openDialog(config: IDialogConfig, type?: NotificationType): MatDialogRef<DialogComponent> {
    switch (type) {
      case NotificationType.Success:
        return this.dialogService.openSuccessDialog(config);
      case NotificationType.Info:
        return this.dialogService.openInfoDialog(config);
      case NotificationType.Warning:
        return this.dialogService.openWarningDialog(config);
      case NotificationType.Error:
        return this.dialogService.openErrorDialog(config);
      default:
        return this.dialogService.openDialog(config);
    }
  }

  openConfirmDialog$ = createEffect(
    (): Observable<any> => {
      return this.actions$.pipe(
        ofType(openConfirmDialog),
        switchMap((payload) => {
          const modalContent = payload.payload;
          const modalResult$ = this.modalService
            .openConfirmDialog({
              OKButtonText: modalContent.OKButtonText,
              body: modalContent.body,
              cancelButtonText: modalContent.cancelButtonText,
              header: modalContent.header,
              options: modalContent.options,
            })
            .confirmed$();
          return combineLatest([modalResult$, of(modalContent.onConfirm)]);
        }),
        map(([result, onConfirm]) => {
          if (result && result !== false && onConfirm) {
            onConfirm();
          }
        }),
      );
    },
    { dispatch: false },
  );

  openAlertDialog$ = createEffect(
    (): Observable<any> => {
      return this.actions$.pipe(
        ofType(openAlertDialog),
        switchMap((payload) => {
          const modalContent = payload.payload;
          const modalResult$ = this.modalService
            .openAlertDialog({
              OKButtonText: modalContent.OKButtonText || 'OK',
              body: modalContent.body,
              header: modalContent.header,
            })
            .confirmed$();
          return combineLatest([modalResult$, of(modalContent.onConfirm)]);
        }),
        map(([_result, onConfirm]) => {
          if (onConfirm) {
            onConfirm();
          }
        }),
      );
    },
    { dispatch: false },
  );

  openWelcomeDialog$ = createEffect(
    (): Observable<any> => {
      return this.actions$.pipe(
        ofType(openWelcomeDialog),
        map(() => {
          this.modalService.openWelcomeDialog().confirmed$();
        }),
      );
    },
    { dispatch: false },
  );

  openCatalogDialog$ = createEffect(
    (): Observable<any> => {
      return this.actions$.pipe(
        ofType(openCatalogDialog),
        switchMap((payload) => {
          const modalContent = payload.payload;
          const modalResult$ = this.modalService
            .openCatalogDialog({
              OKButtonText: modalContent.OKButtonText || 'OK',
              body: modalContent.body,
              tenantId: modalContent.tenantId,
              header: modalContent.header,
            })
            .confirmed$();
          return combineLatest([modalResult$]);
        }),
      );
    },
    { dispatch: false },
  );

  openWebTechDialog$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(openWebTechDialog),
      switchMap((payload) => {
        const dialogRef = this.openDialog(payload.config, payload.notificationType);
        return combineLatest([dialogRef.componentInstance.onClick, of(dialogRef), of(payload)]);
      }),
      switchMap(([dialogButton, dialogRef, payload]) => {
        if (dialogButton.buttonStyle === ActionButtonStyles.Main && payload.mainBtnHandler) {
          payload.mainBtnHandler();
        }
        return combineLatest([dialogRef.afterClosed(), of(payload)]);
      }),
      map(([_closed, payload]) => {
        if (payload.closeHandler) {
          return payload.closeHandler;
        }
        return emptyAction();
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private modalService: ModalService,
    private dialogService: DialogService,
  ) {}
}
