import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { AppErrorCode } from '@core/common-constants';

export class ApiError {
  // code#serviceKind

  private readonly url: string;
  private readonly retryAfter: string;
  private readonly status: number;
  private readonly requestId: string;
  private readonly errorCode: AppErrorCode;
  private readonly errorCodeParts: string[];
  private readonly errorResponse: HttpErrorResponse;
  private error: Record<string, string>;
  // pull out requestid
  // logging function
  // validation while tranforming to sepcific error
  constructor(errorResponse: HttpErrorResponse) {
    this.errorResponse = errorResponse;
    this.url = String(errorResponse.url);
    this.retryAfter = errorResponse.error?.retryAfter;
    this.status = errorResponse.status;
    this.requestId = String(this.errorResponse.headers.get('RequestId'));
    this.errorCode = errorResponse.error?.errorCode
      ? errorResponse.error?.errorCode.split('#')[0]
      : AppErrorCode.Unknown;
    this.errorCodeParts = errorResponse.error?.errorCode
      ? errorResponse.error?.errorCode.split('#')
      : [AppErrorCode.Unknown];
    this.error = errorResponse.error;
  }
  get ErrorCode(): AppErrorCode {
    return this.errorCode;
  }
  get ErrorCodeParts(): string[] {
    return this.errorCodeParts;
  }
  get ErrorData(): Record<string, string> {
    return this.error;
  }
  get RequestId(): string {
    return this.requestId;
  }
  get Status(): HttpStatusCode {
    return this.status;
  }
  get Url(): string {
    return this.url;
  }
  get RetryAfter(): string {
    return this.retryAfter;
  }
}
