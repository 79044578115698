import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { toAppId } from '@app/v2/shared/utils';
import { AppErrorCode } from '@core/common-constants';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { ActionButtonStyles, NotificationType } from '@ra-web-tech-ui-toolkit/components';
import { ErrorHandlers } from '@servicesV2/apierror-handlers.service';
import { CommandRequestBuilderService } from '@servicesV2/command-request-builder.service';
import { TenantEvent, TrialEvent } from '@servicesV2/command-request.service';
import { ApiEndPoints, DataService } from '@servicesV2/data.service';
import { combineLatest, combineLatestWith, Observable, of, switchMap } from 'rxjs';
import { DomainEvent } from '../../models/domain-event';
import { ControlPageFacade, navigateTo } from '../control-page';
import { PendingServiceV2, TrialLinkStatus } from '../lemans-app.model';
import { openWebTechDialog } from '../modal';
import { SignalRFacade } from '../signalR';
import { displayMessage } from '../snackbar';
import { getTenantEffectiveRolesAndServices, getUserPreferencesSuccess } from '../user-data';
import {
  allocateTrial,
  clearTrialGridRefresh,
  getTrialReservationInfo,
  redeemTrial,
  redeemTrialSuccess,
  refreshTrialGrid,
  setTrialBundles,
  setTrialReservationInfo,
} from './trials.actions';

@Injectable({
  providedIn: 'root',
})
export class TrialsEffects {
  private readonly DASHBOARD_PATH = '/dashboard';
  private readonly ENTITLEMENT_PATH = '/entitlement';

  getTrialBundles$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getUserPreferencesSuccess),
      switchMap(() => this.dataService.getTrialBundles$()),
      switchMap((trialBundles) => {
        return [setTrialBundles({ payload: trialBundles })];
      }),
    );
  });

  getTrialReservationInfo$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(getTrialReservationInfo),
      switchMap((request) => {
        return this.dataService.getTrialReservationInfo$(request.payload);
      }),
      switchMap((trialReservation) => {
        let title = 'Trial already redeemed';
        let message =
          'This trial link has already been used. Please check your account trials. If you still have questions, reach out to your sales representative.';

        if (trialReservation.status === TrialLinkStatus.CANCELLED) {
          title = 'Invalid Trial Link';
          message = 'Please contact your sales representative for a new link. We apologize for the inconvenience.';
        } else if (trialReservation.status === TrialLinkStatus.LINKEXPIRED) {
          title = 'Invalid Trial Link';
          message =
            'Looks like your link has expired. Please contact your sales representative for a new link. We apologize for the inconvenience.';
        }
        const config = {
          title: title,
          message: message,
          buttons: [{ label: 'Close', buttonStyle: ActionButtonStyles.Main }],
          showCloseIconButton: true,
        };
        const actions: Action[] = [];
        if (trialReservation.status !== TrialLinkStatus.UNREDEEMED) {
          actions.push(openWebTechDialog({ config: config, notificationType: NotificationType.Error }));
          actions.push(navigateTo({ path: this.DASHBOARD_PATH }));
          return actions;
        }
        return [setTrialReservationInfo({ payload: trialReservation })];
      }),
    );
  });

  redeemTrial$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(redeemTrial),
      combineLatestWith(this.signalRFacade.ensureIsConnected$),
      switchMap(([payload]) => {
        const url = `${ApiEndPoints.Trial}/${payload.payload.campaignId}/reservations/${payload.payload.trialReservationId}/redeem`;
        const commandRequest = this.commandRequestBuilderService
          .new(url, 'PUT', TrialEvent.TrialRedeemed)
          .withWaitOn202Accepted()
          .withErrorHandler({
            [HttpStatusCode.BadRequest]: this.errorHandlers.handleTrialErrors,
          });
        return combineLatest([commandRequest.invoke$(), of(payload.payload)]);
      }),
      switchMap(([response, redeemTrial]) => {
        if (response.error && response.errorActions) {
          return response.errorActions;
        }

        // TODO: remove this logic to parse event data once API support available
        // TODO: Push Gainsight event as per the Trial Bundle
        this.router.navigate([this.ENTITLEMENT_PATH], {
          queryParams: { selectedTabIndex: 2 },
        });
        return [
          displayMessage({
            payload: {
              message: 'Trial redeemed successfully! Trial has been added to your account',
              type: 'Success',
            },
          }),
          redeemTrialSuccess({ payload: redeemTrial }),
        ];
      }),
    );
  });

  allocateTrial$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(allocateTrial),
      switchMap((payload) => {
        const url = `${ApiEndPoints.Users}${payload.payload.userId}/trials/${payload.payload.trialId}/tenantallocate`;
        const commandRequest = this.commandRequestBuilderService
          .new(
            url,
            'PUT',
            (msg) =>
              msg.type === TrialEvent.TrialAllocated ||
              msg.type === TrialEvent.TrialAllocationFailed ||
              msg.type === TenantEvent.TrialAllocationUserActionPending,
          )
          .withTenantHeader(payload.payload.tenantId)
          .withWaitOn202Accepted();

        return combineLatest([commandRequest.invoke$(), of(payload), this.controlPageFacade.getAppsMap$]);
      }),
      switchMap(([response, payload, appsMap]) => {
        if (!response.error && response.message?.type === TrialEvent.TrialAllocated) {
          return [
            getTenantEffectiveRolesAndServices({ payload: String(payload.payload.tenantId) }),
            refreshTrialGrid(),
            displayMessage({
              payload: {
                message: `${payload.payload.trialBundle.name} is now active on ${payload.payload.tenantName}.`,
                type: 'Success',
              },
            }),
            clearTrialGridRefresh(),
          ];
        } else if (response.message?.type === TrialEvent.TrialAllocationFailed) {
          let errorMsg = 'Trial could not be allocated.';
          const domainEventError = new DomainEvent(response.message);
          // TODO: Till the time back-end supports standard error format
          if (domainEventError.ErrorCode === AppErrorCode.TenantServiceProvisioningPending) {
            const appId = toAppId(domainEventError.ErrorCodeParts[1]);
            errorMsg = `${appsMap[appId]?.appName} needs further action to be enabled for your organization. Please open to complete.`;
            return [
              displayMessage({
                payload: {
                  message: errorMsg,
                  type: 'Info',
                  customBtn: {
                    label: 'Go to Home',
                    navigateTo: '/dashboard',
                  },
                },
              }),
            ];
          } else if (domainEventError.ErrorCode === AppErrorCode.EntitlementTransactionInProgress) {
            return [
              displayMessage({
                payload: {
                  message: 'Service could not be allocated. Please try again.',
                  type: 'Error',
                },
              }),
            ];
          }
          return [
            displayMessage({
              payload: {
                message: errorMsg,
                type: 'Error',
              },
            }),
          ];
        } else if (response.message?.type === TenantEvent.TrialAllocationUserActionPending) {
          const service = new DomainEvent<PendingServiceV2>(response.message);
          const appId = service?.EventData.service.kind;
          const serviceName = appsMap[appId]?.appName;
          const errorMsg = `${serviceName} needs further action to be enabled for your organization. Please open to complete.`;
          return [
            getTenantEffectiveRolesAndServices({ payload: String(payload.payload.tenantId) }),
            refreshTrialGrid(),
            displayMessage({
              payload: {
                message: errorMsg,
                type: 'Info',
                customBtn: {
                  label: 'Go to Home',
                  navigateTo: '/dashboard',
                },
              },
            }),
            clearTrialGridRefresh(),
          ];
        } else {
          return [
            displayMessage({
              payload: {
                message: `Trial could not be allocated.`,
                type: 'Error',
              },
            }),
          ];
        }
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly dataService: DataService,
    private readonly commandRequestBuilderService: CommandRequestBuilderService,
    private readonly signalRFacade: SignalRFacade,
    private readonly controlPageFacade: ControlPageFacade,
    private readonly router: Router,
    private readonly errorHandlers: ErrorHandlers,
  ) {}
}
