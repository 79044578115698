/**
 * TO DO: review for dead code
 */

import { HttpStatusCode } from '@angular/common/http';
import { MessageIcons, Role } from '@app/+state/lemans-app.model';
import * as _ from 'lodash';

export interface MessageIconMap {
  [key: string]: { icon: MessageIcons; message: string };
}

export class CommonConstants {
  // Session Timeout Configuration
  public static readonly idealTimeout: number = 1500000; //25 Minutes
  public static readonly sessionTimeout: number = 180; // 3 Minute

  public static readonly responseMessageIconColorMap: MessageIconMap = {
    'add-access': { icon: MessageIcons.Success, message: 'Access has been added successfully.' },
    'duplicate-add-records': {
      icon: MessageIcons.Error,
      message: 'Some records are already associated with the selected user.',
    },
    'duplicate-edit-records': {
      icon: MessageIcons.Error,
      message: 'Some records are already associated with this user.',
    },
    'edit-access': { icon: MessageIcons.Success, message: 'Access has been modified successfully.' },
    'invite-user': { icon: MessageIcons.Error, message: 'Error - Email address you entered is not valid' },
    'license-agreement': { icon: MessageIcons.LicenseScroll, message: "Scroll to enable 'Accept' button" },
    'remove-access': { icon: MessageIcons.Info, message: 'Access has been removed successfully.' },
  };

  // Help Screen Data
  public static readonly CONTACT_SUPPORT_URL =
    'https://rockwellautomation.custhelp.com/app/chat/chat_landing/contacts.email/';
  static readonly entitlementNewPeriodInDays = 3;
  static readonly trialExpireSoonDays = 7;

  public static getEmailDomain(emailString: string): string {
    return emailString.split('@')[1];
  }

  public static readonly cleanLeftTrailingZeros = (str: string): string => {
    return str.replace(/^0+/, '');
  };

  public static readonly UserIDPConnections = {
    MyRockwell: 'MyRockwell',
  };
}

export const SELECTABLE_ROLES = [Role.Admin, Role.Contributor];

export enum AppErrorCode {
  TenantArchivedReadOnly = 'TenantArchivedReadOnly',
  EntitlementAlreadyConsumed = 'EntitlementAlreadyConsumed',
  EntitlementTransactionInProgress = 'EntitlementTransactionInProgress',
  InactiveEntitlement = 'EntitlementError-InactiveEntitlement',
  PlatformEntitlementsCannotOverlap = 'EntitlementError-platform entitlements cannot overlap',
  CannotApplyEntitlementToPersonalTenant = 'CannotApplyEntitlementToPersonalTenant',
  EntitlementNotActive = 'EntitlementNotActive',
  EntitlementDoesNotSupportMoreThanOneQuantity = 'EntitlementError-Entitlement kind disallows quantity more than 1',
  TenantServiceProvisioningPending = 'TenantServiceProvisioningPending',
  TrialAlreadyRedeemed = 'TrialAlreadyRedeemed',
  UserIdClaimNotFound = 'UserIdClaimNotFound',
  UserEmailClaimNotFound = 'UserEmailClaimNotFound',
  FirstNameClaimNotFound = 'FirstNameClaimNotFound',
  LastNameClaimNotFound = 'LastNameClaimNotFound',
  Unknown = 'Unknown',
}

export type ErrorMatcher = {
  matchBy: AppErrorCode | HttpStatusCode | RegExp;
  message: string | _.TemplateExecutor;
};

const defaultErrorMatchers: ErrorMatcher[] = [
  {
    matchBy: AppErrorCode.TenantServiceProvisioningPending,
    message: _.template(
      '${appName} needs further action to be enabled for your organization. Please open to complete.',
    ),
  },
  { matchBy: AppErrorCode.EntitlementAlreadyConsumed, message: 'Entitlement already consumed.' },
  {
    matchBy: AppErrorCode.EntitlementTransactionInProgress,
    message: 'Service provisioning is in progress, please complete the provisioning before applying the entitlement.',
  },
  { matchBy: AppErrorCode.InactiveEntitlement, message: 'Cannot allocate, the entitlement is no more active.' },
  {
    matchBy: AppErrorCode.PlatformEntitlementsCannotOverlap,
    message: 'Service already has a platform entitlement allocated for the time period.',
  },
  {
    matchBy: AppErrorCode.CannotApplyEntitlementToPersonalTenant,
    message: 'Entitlements cannot be allocated to a personal organization.',
  },
  { matchBy: AppErrorCode.EntitlementNotActive, message: 'Entitlement is not active.' },
  {
    matchBy: AppErrorCode.EntitlementDoesNotSupportMoreThanOneQuantity,
    message: 'Entitlement does not support more than one quantity to be allocated at a time.',
  },
  { matchBy: AppErrorCode.UserIdClaimNotFound, message: 'uid claim missing' },
  { matchBy: AppErrorCode.UserEmailClaimNotFound, message: 'email claim missing' },
  { matchBy: AppErrorCode.FirstNameClaimNotFound, message: 'first_name claim missing' },
  { matchBy: AppErrorCode.LastNameClaimNotFound, message: 'given_name claim missing' },
  { matchBy: 403, message: 'Insufficient Permissions: Redirecting to dashboard' },
  {
    matchBy: /EntitlementError-.*does not meet pre-requisite platform catalog requirement/i,
    message: _.template(
      'Can’t allocate this entitlement when there is no active ${productFamilyName} product entitlement allocated.',
    ),
  },
  {
    matchBy: /EntitlementError-.*does not overlap any platform entitlement/i,
    message: 'Add-On entitlement dates does not overlap with platform entitlement date',
  },
];
export function getErrorMessage(errorCode: AppErrorCode | HttpStatusCode | string, context: any = {}): string {
  const matchedErrorMsg = defaultErrorMatchers.find((x) => {
    if (x.matchBy instanceof RegExp && typeof errorCode === 'string') {
      return x.matchBy.test(errorCode);
    } else {
      return x.matchBy === errorCode;
    }
  });
  if (!matchedErrorMsg) {
    return '';
  }
  if (typeof matchedErrorMsg.message === 'string') {
    return matchedErrorMsg.message;
  }
  return matchedErrorMsg.message(context);
}

export const minDate: string = '0001-01-01T00:00:00+00:00';

export const maxDate: string = '9999-12-31T23:59:59.9999999+00:00';

export const DOMAINS = {
  PUBLIC: 'Public',
  PRIVATE: 'Private',
};
