import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { TrialStatus } from '@ra-state';
import { ITooltipConfig, TooltipPosition } from '@ra-web-tech-ui-toolkit/components';

@Component({
  selector: 'app-ag-status-renderer',
  templateUrl: './ag-status-renderer.component.html',
  styleUrls: ['./ag-status-renderer.component.scss'],
})
export class AgStatusRendererComponent {
  TRIAL_STATUS = TrialStatus;
  readonly tooltipConfig: ITooltipConfig = { position: TooltipPosition.Top, maxWidth: 400 };
  private params: any;

  refresh(_params: ICellRendererParams): boolean {
    return true;
  }
  agInit(params: any): void {
    this.params = params;
  }

  get Status(): TrialStatus {
    return this.params?.data.status;
  }

  constructor() {}
}
