import { Injectable } from '@angular/core';
import { displayMessageAction, toAppId } from '@app/v2/shared/utils';
import { ApiError } from '@core/api-error';
import { AppErrorCode, getErrorMessage } from '@core/common-constants';
import { Action } from '@ngrx/store';
import { ApplicationMap, ControlPageFacade, displayMessage, navigateTo, openWebTechDialog } from '@ra-state';
import { ActionButtonStyles, NotificationType } from '@ra-web-tech-ui-toolkit/components';
import { tap } from 'rxjs';
import { ApiEndPoints } from './data.service';
import { AccessRequestSendError, SendAccessRequestError } from '@app/+state/access-requests/access-request.domain';

export function unknownErrorHandler(): Action {
  return displayMessage({
    payload: {
      message: 'Something went wrong',
      type: 'Error',
      customBtn: {
        label: 'Go to Home',
        navigateTo: '/dashboard',
      },
    },
  });
}

export function showErrorDialog(messsage: string = '', title: string = 'Invite cannot be accepted'): Action {
  return openWebTechDialog({
    config: {
      title: title,
      message: messsage,
      messageType: NotificationType.Error,
      buttons: [
        {
          label: 'OK',
          buttonStyle: ActionButtonStyles.Main,
          shouldAutofocus: true,
        },
      ],
    },
    notificationType: NotificationType.Error,
  });
}
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlers {
  appsMap: ApplicationMap = {};

  // TODO: this is only used to get app name once
  appsMap$ = this.controlPageFacade.getAppsMap$
    .pipe(
      tap((appsMap) => {
        this.appsMap = appsMap;
      }),
    )
    .subscribe();

  handleEntitlementErrors = (apiError: ApiError, _body: any, context: any = {}): Action[] => {
    if (!apiError.Url.includes(ApiEndPoints.Users) || !apiError.Url.includes('/entitlements/')) {
      throw new Error('Url mismatch: API url does not match entitlements endpoint');
    }

    //// this entire switch case should be replaced with preconfigured matchers that are part of the
    /// command request builder
    /// it should match the error code (number/string/regex) and return a lodash.template string
    switch (apiError.ErrorCode) {
      case AppErrorCode.TenantServiceProvisioningPending: {
        const appId = toAppId(apiError.ErrorCodeParts[1]);
        const msg = getErrorMessage(apiError.ErrorCode, { ...context, appName: this.appsMap[appId]?.appName });
        return [
          displayMessage({
            payload: {
              message: msg,
              type: 'Info',
              customBtn: {
                label: 'Go to Home',
                navigateTo: '/dashboard',
              },
            },
          }),
        ];
      }
      default: {
        const message = getErrorMessage(apiError.ErrorCode, context);
        if (message) {
          return [displayMessageAction(message)];
        }
        return [];
      }
    }
  };

  handleAccessRequestErrors = (apiError: ApiError, _body: any): Action[] => {
    if (!apiError.Url.includes(ApiEndPoints.AccessRequest)) {
      throw new Error('Url mismatch: API url does not match invitations endpoint');
    }

    const errorData: SendAccessRequestError = apiError.ErrorData as any;

    if (errorData === null) {
      return [];
    }

    if (errorData.errorCode === AccessRequestSendError.UserAlreadyHasAccessToSpecifiedResource) {
      return [displayMessageAction('Your account already has access to the organization.')];
    } else {
      return [];
    }
  };

  handleTrialErrors = (apiError: ApiError, _body: any): Action[] => {
    if (!apiError.Url.includes(ApiEndPoints.Trial)) {
      throw new Error('Url mismatch: API url does not match trial endpoint');
    }

    switch (apiError.ErrorCode) {
      case AppErrorCode.TrialAlreadyRedeemed: {
        const config = {
          title: 'Trial already redeemed',
          message:
            'This trial link has already been used. Please check your account trials. If you still have questions, reach out to your sales representative.',
          buttons: [{ label: 'Close', buttonStyle: ActionButtonStyles.Main }],
          showCloseIconButton: true,
        };

        return [
          openWebTechDialog({ config: config, notificationType: NotificationType.Error }),
          navigateTo({ path: '/dashboard' }),
        ];
      }
      default: {
        const config2 = {
          title: 'Invalid Trial Link',
          message: 'Please contact your sales representative for a new link. We apologize for the inconvenience.',
          buttons: [{ label: 'Close', buttonStyle: ActionButtonStyles.Main }],
          showCloseIconButton: true,
        };
        return [
          openWebTechDialog({ config: config2, notificationType: NotificationType.Error }),
          navigateTo({ path: '/dashboard' }),
        ];
      }
    }
  };

  constructor(private readonly controlPageFacade: ControlPageFacade) {}
}
