import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';

@Component({
  selector: 'app-ag-remove-button',
  styleUrls: ['./ag-remove-button.component.scss'],
  templateUrl: './ag-remove-button.component.html',
})
export class AgRemoveButtonComponent implements ICellRendererAngularComp {
  private params: any;
  refresh(): boolean {
    return false;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  btnClickedHandler(): void {
    const deletedRow = this.params.node.data;
    this.params.api.applyTransaction({ remove: [deletedRow] });
  }
}
