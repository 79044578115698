import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { displaySnackBarMessage } from '@app/v2/shared/utils';
import { ApiError } from '@core/api-error';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, createAction, createSelector, props, Store } from '@ngrx/store';
import {
  AppState,
  displayMessage,
  emptyAction,
  ICommandResponse,
  openWebTechDialog,
  SignalRFacade,
  switchTenant,
} from '@ra-state';
import { ActionButtonStyles, IDialogConfig, NotificationType } from '@ra-web-tech-ui-toolkit/components';
import { CommonService } from '@rockwell-automation-inc/service';
import { CommandRequestBuilderService } from '@servicesV2/command-request-builder.service';
import { InvitationEvent, UserEvent } from '@servicesV2/command-request.service';
import { ApiEndPoints } from '@servicesV2/data.service';
import { LoggerService } from '@servicesV2/logger.service';
import { combineLatest, combineLatestWith, forkJoin, mergeMap, Observable, of, switchMap } from 'rxjs';
import { CreateInvitation, handleAcceptInvitationErrors, handleSendInvitationErrors } from './domain';

export const createInvitations = createAction(
  '[INVITATION] Create Invitations',
  props<{ payload: CreateInvitation[] }>(),
);
export const createInvitationsSuccess = createAction('[INVITATION] Create Invitations Success');
export const createInvitation = createAction('[INVITATION] Create Invitation', props<{ payload: CreateInvitation }>());
export const createInvitationSuccess = createAction('[INVITATION] Create Invitation Success');
export const resendInvitation = createAction(
  '[INVITATION] Resend Invitation',
  props<{ tenantId: string; invitationId: string }>(),
);
export const resendInvitationSuccess = createAction('[INVITATION] Resend Invitation Success');
export const deleteInvitation = createAction(
  '[INVITATION] Delete Invitation',
  props<{ tenantId: string; invitationId: string }>(),
);
export const deleteInvitationSuccess = createAction('[INVITATION] Delete Invitation Success');
export const setInviteError = createAction(
  '[INVITATION] Set Invite Error',
  props<{ payload: { error: ApiError; email: string } }>(),
);

export const acceptInvitation = createAction('[INVITATION] Accept Invitation', props<{ invitationId: string }>());

const resetInviteError = createAction('[INVITATION] reset Invite Error array');
const resetInvitationGridRefresh = createAction('[INVITATION] Reset Invitation Grid Refresh state');
export const InvitationActions = {
  acceptInvitation,
  createInvitations,
  createInvitationsSuccess,
  deleteInvitation,
  deleteInvitationSuccess,
  resendInvitation,
  resendInvitationSuccess,
  resetInviteError,
  setInviteError,
  resetInvitationGridRefresh,
};

type InviteError = {
  error: ApiError;
  email: string;
};
export type InvitationState = {
  inviteErrors: InviteError[];
  refreshGrid: boolean;
};
// FIXME duplication; can't ref the selector since that results in a cyclic dep
const sudf = (state: AppState): any => state.invitation;
const Selectors = {
  selectInviteErrors: createSelector(sudf, (state: InvitationState) => state.inviteErrors),
  selectRefreshInvitationGrid: createSelector(sudf, (state: InvitationState) => state.refreshGrid),
  // selectInviteErrors: createSelector(selectUserDataFeature, (state: UserPreferences) => state.inviteErrors)
};

export class InvitationFacade {
  inviteErrors$ = this.store$.select(Selectors.selectInviteErrors);
  refreshInvitationGrid$ = this.store$.select(Selectors.selectRefreshInvitationGrid);
  constructor(private store$: Store<AppState>) {}
  resetInviteErrors(): void {
    this.store$.dispatch(InvitationActions.resetInviteError());
  }
  createInvitations(invitations: CreateInvitation[]): void {
    this.store$.dispatch(InvitationActions.createInvitations({ payload: invitations }));
    this.store$.dispatch(InvitationActions.resetInviteError());
  }
  resendInvitation(tenantId: string, invitationId: string): void {
    this.store$.dispatch(InvitationActions.resendInvitation({ invitationId: invitationId, tenantId: tenantId }));
  }
  deleteInvitation(tenantId: string, invitationId: string): void {
    this.store$.dispatch(InvitationActions.deleteInvitation({ invitationId: invitationId, tenantId: tenantId }));
  }
  acceptInvitation(invitationId: string): void {
    this.store$.dispatch(InvitationActions.acceptInvitation({ invitationId: invitationId }));
  }
  resetInvitationGridRefresh(): void {
    this.store$.dispatch(InvitationActions.resetInvitationGridRefresh());
  }
}
@Injectable({
  providedIn: 'root',
})
export class InvitationEffects {
  constructor(
    private actions$: Actions,
    private readonly commandRequestBuilderService: CommandRequestBuilderService,
    private readonly signalRFacade: SignalRFacade,
    private readonly router: Router,
    private readonly logger: LoggerService,
    private readonly navbarCommonService: CommonService,
  ) {}

  acceptInvitation$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(acceptInvitation),
      combineLatestWith(this.signalRFacade.ensureIsConnected$),
      switchMap(([value]) => {
        const url = `${ApiEndPoints.Invitations}${value.invitationId}/accept2`;
        const commandRequest = this.commandRequestBuilderService
          .new(
            url,
            'POST',
            (ev) => ev.type === UserEvent.UserRoleAssigned || ev.type === InvitationEvent.UserRoleUpdateErroredV2,
          )
          .withWaitOn202Accepted()
          .withErrorHandler({
            [HttpStatusCode.Conflict]: handleAcceptInvitationErrors,
          });
        return commandRequest.invoke$();
      }),
      mergeMap((response) => {
        this.logger.log('Invitation state update msg recd', response);
        const accept2Response = response.response;
        let tenantId: string | null = accept2Response?.body?.tenantId;
        if (response.message?.type === UserEvent.UserRoleAssigned) {
          this.navbarCommonService.refreshOrganizations();
        } else {
          tenantId = null;
        }
        return combineLatest([of(response), of(accept2Response), of(tenantId)]);
      }),
      mergeMap(([response, accept2Response, tenantId]) => {
        this.router.navigate([], {
          queryParams: {
            invitationId: null,
          },
          queryParamsHandling: 'merge',
        });
        if (response.error && response.errorActions) {
          return response.errorActions;
        }
        if (accept2Response?.body.outcome === 'Ok' && accept2Response.body.previouslyAccepted) {
          const config: IDialogConfig = {
            title: 'Invite cannot be accepted',
            message:
              'This invite has already been used. If you do not have access, reach out to your administrator for a new invite.',
            messageType: NotificationType.Error,
            buttons: [
              {
                label: 'Close',
                buttonStyle: ActionButtonStyles.Main,
                shouldAutofocus: true,
              },
            ],
          };
          return [openWebTechDialog({ config: config })];
        }
        if (tenantId === null) {
          return [emptyAction()];
        }
        const acceptedInvitationAction = displayMessage({
          payload: {
            message: `Invite to ${accept2Response?.body?.resourceName} accepted.`,
            type: 'Success',
          },
        });
        return [acceptedInvitationAction, switchTenant({ value: String(tenantId) })];
      }),
    );
  });

  createInvitations$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(InvitationActions.createInvitations),
      switchMap((invitations) => {
        const requests$: Observable<any>[] = [];
        const list = invitations.payload;
        list.forEach((item) => {
          const commandRequest = this.commandRequestBuilderService
            .new(ApiEndPoints.Invitations, 'POST', InvitationEvent.InvitationCreated)
            .withTenantHeader(item.tenantId)
            .withBody(item)
            .withErrorHandler({
              [HttpStatusCode.BadRequest]: handleSendInvitationErrors,
              [HttpStatusCode.Conflict]: handleSendInvitationErrors,
            });

          requests$.push(commandRequest.invoke$());
        });
        return forkJoin(requests$);
      }),
      mergeMap((responses: ICommandResponse[]) => {
        let actions: Action[] = [];
        const errorResponses: ICommandResponse[] = [];
        const successResponses: ICommandResponse[] = [];
        responses.forEach((resp) => {
          if (Boolean(resp.error)) {
            errorResponses.push(resp);
          } else {
            successResponses.push(resp);
          }
        });

        if (successResponses.length) {
          let message = 'Invite sent';
          if (successResponses.length > 1) {
            message = `${successResponses.length} invites sent`;
          }
          actions = [
            InvitationActions.createInvitationsSuccess(),
            displayMessage({
              payload: {
                message,
                type: 'Success',
              },
            }),
            InvitationActions.resetInvitationGridRefresh(),
          ];
        }

        errorResponses.forEach((errorResp) => {
          if (errorResp.errorActions) {
            actions = actions.concat(errorResp.errorActions);
          }
        });
        return actions;
      }),
    );
  });

  resendInvitation$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(InvitationActions.resendInvitation),
      switchMap((value) => {
        const url = `/api/invitations/${value.invitationId}/reissue`;
        const commandRequest = this.commandRequestBuilderService
          .new(url, 'POST', InvitationEvent.InvitationReissuedV2)
          .withTenantHeader(value.tenantId)
          .withWaitOn200Ok()
          .withErrorHandler({
            [HttpStatusCode.BadRequest]: handleSendInvitationErrors,
            [HttpStatusCode.Conflict]: displaySnackBarMessage(
              'The invitation could not be resent. Refresh the page and try again.',
              'Warning',
            ),
          });
        return commandRequest.invoke$();
      }),
      switchMap((response) => {
        if (response.error && response.errorActions) {
          return response.errorActions;
        }
        return [
          InvitationActions.resendInvitationSuccess(),
          displayMessage({
            payload: {
              message: 'Invitation resent successfully!. You will be notified once the user joins the organization.',
              type: 'Success',
            },
          }),
          InvitationActions.resetInvitationGridRefresh(),
        ];
      }),
    );
  });

  deleteInvitation$ = createEffect((): Observable<any> => {
    return this.actions$.pipe(
      ofType(InvitationActions.deleteInvitation),
      switchMap((value) => {
        const url = `/api/invitations/${value.invitationId}`;
        const commandRequest = this.commandRequestBuilderService
          .new(url, 'DELETE', InvitationEvent.InvitationFinalized)
          .withTenantHeader(value.tenantId)
          .withWaitOn200Ok()
          .withErrorHandler({
            [HttpStatusCode.BadRequest]: handleSendInvitationErrors,
            [HttpStatusCode.Conflict]: displaySnackBarMessage(
              'The invitation could not be deleted. Refresh the page and try again.',
              'Warning',
            ),
            [HttpStatusCode.Gone]: displaySnackBarMessage(
              'Invitation has been deleted/canceled. Please refresh.',
              'Info',
            ),
          });
        return commandRequest.invoke$();
      }),
      switchMap((response) => {
        if (response.error && response.errorActions) {
          return response.errorActions;
        }
        return [
          InvitationActions.deleteInvitationSuccess(),
          displayMessage({
            payload: {
              message: 'Invitation has been deleted/canceled successfully.',
              type: 'Info',
            },
          }),
          InvitationActions.resetInvitationGridRefresh(),
        ];
      }),
    );
  });
}
